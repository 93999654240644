import { React, useContext } from 'react';
import { rem } from '@mantine/core';
import { Container, Card, Modal, Switch, Paper } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { Image, Indicator, TextInput, Box } from "@mantine/core"
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Button } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { Group, Text } from '@mantine/core';
import { IconUpload, IconPhoto, IconDownload, IconBrandInstagram } from '@tabler/icons-react';
import { API } from 'aws-amplify';
import { guestDataContext } from '../../contexts/GuestDataContext';
import { Hearts } from "react-loader-spinner"
import { notifications } from '@mantine/notifications';
import { IconX, IconCheck, IconAlertTriangle } from '@tabler/icons-react';
import { Progress, Loader } from '@mantine/core';

import { FaRegHeart, FaHeart } from "react-icons/fa";

import { useEffect, useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { PictureText } from '../../fragments/PictureText';

import { Gallery } from "react-grid-gallery"
import { LuGalleryThumbnails } from "react-icons/lu";
import { RiGalleryView } from "react-icons/ri";

import { LazyLoadingImage } from '../../fragments/LazyLoadingImage';

function PictureOutletCombined(trigger) {
    const { guest, rsvp } = useContext(guestDataContext);

    const [featuredPictures, setFeaturedPictures] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const [isLikeUpdating, setIsLikeUpdating] = useState(false);


    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSubscribing, setIsLoadingSubscribing] = useState(false);

    const [newUserContent, setNewUserContent] = useState({
        "undownloadedCount": {
            "cologne": [],
            "italy": [],
            "italy-vacation": []

        },
        "undownloadedKeys": {
            "cologne": [],
            "italy": [],
            "italy-vacation": []
        },
        "downloadedCount": {
            "cologne": [],
            "italy": [],
            "italy-vacation": []
        },
        "totalCount": {
            "cologne": 0,
            "italy": 0,
            "italy-vacation": 0
        },
        "unviewedPictures": {
            "cologne": [],
            "italy": [],
            "italy-vacation": []
        }

    });
    const [newFilesDownloaded, setNewFilesDownloaded] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
    const [isGalleryModeActive, setIsGalleryModeActive] = useState(false);
    const [gallerySelectedPicture, setGallerySelectedPicture] = useState({ thumbnailUrl: "", Key: "" });
    const [updateViewed, setUpdateViewed] = useState(false);



    const [modalLocation, setModalLocation] = useState("cologne");
    const [modalType, setModalType] = useState("photographer");

    const [isModalGuestPicturesOpen, setIsModalGuestPicturesOpen] = useState(false);

    const [cachedPreviewUrls, setCachedPreviewUrls] = useState({});

    const [viewedPictures, setViewedPictures] = useState([]);
    const [debouncedViewedPictures] = useDebouncedValue(viewedPictures, 1500);


    const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);
    const [previewPictures, setPreviewPictures] = useState([]);
    const [emblaApi, setEmbla] = useState(null);
    const [emblaApiUserPictures, setEmblaUserPictures] = useState(null);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [currentSlideIndexUserPictures, setCurrentSlideIndexUserPictures] = useState(0);

    const [userInputMail, setUserInputMail] = useState('');
    const [userInputMailError, setUserInputMailError] = useState('');



    const [favoritePictures, setfavoritePictures] = useState({ "photographer": { "cologne": [], "italy": [] }, "uploads": { "cologne": [], "italy": [] }, "fotobox": { "cologne": [], "italy": [] } });
    const [showOnlyFavoritesChecked, setShowOnlyFavoritesChecked] = useState(false);

    const [showOnlyNewPictures, setShowOnlyNewPictures] = useState(false);
    const [guestSubscriptionStatus, setGuestSubscriptionStatus] = useState(false);


    useEffect(() => {
        setIsLoading(true)
        API.get("WeddingApi", `/pictures/featured?id=${guest.id}`)
            .then(response => {
                setFeaturedPictures(response)
                setIsLoading(false)
            })
            .catch(error => {
                //console.log(error)

            })
        API.get("WeddingApi", `/pictures/subscribe?id=${guest.id}`)
            .then(response => {
                setGuestSubscriptionStatus(response)
            })
            .catch(error => {
                console.log(error)

            })



    }, [guest.id]);
    useEffect(() => {
        //initial effect
        if (guest?.attendance === true) {
            API.get("WeddingApi", `/pictures/cologne/photographer_preview?id=${guest.id}`)
                .then(response => {
                    setCachedPreviewUrls(prevState => ({
                        ...prevState,
                        "cologne": {
                            ...prevState["cologne"],
                            "photographer": {
                                data: response,
                                expiration: new Date().getTime() + 10 * 60 * 1000  // Set expiration time to 10 minutes from now
                            }
                        }
                    }));
                })
                .catch(error => {
                    //console.log(error)
                })
            API.get("WeddingApi", `/pictures/cologne/fotobox_preview?id=${guest.id}`)
                .then(response => {
                    setCachedPreviewUrls(prevState => ({
                        ...prevState,
                        "cologne": {
                            ...prevState["cologne"],
                            "fotobox": {
                                data: response,
                                expiration: new Date().getTime() + 10 * 60 * 1000  // Set expiration time to 10 minutes from now
                            }
                        }
                    }));
                })
            API.get("WeddingApi", `/pictures/cologne/userupload_preview?id=${guest.id}`)
                .then(response => {
                    setCachedPreviewUrls(prevState => ({
                        ...prevState,
                        "cologne": {
                            ...prevState["cologne"],
                            "uploads": {
                                data: response,
                                expiration: new Date().getTime() + 10 * 60 * 1000  // Set expiration time to 10 minutes from now
                            }
                        }
                    }));
                })
                .catch(error => {
                    console.log(error)
                })

            if (guest && guest?.invitedEvents?.italy === true) {
                API.get("WeddingApi", `/pictures/italy/photographer_preview?id=${guest.id}`)
                    .then(response => {
                        setCachedPreviewUrls(prevState => ({
                            ...prevState,
                            "italy": {
                                ...prevState["italy"],
                                "photographer": {
                                    data: response,
                                    expiration: new Date().getTime() + 10 * 60 * 1000  // Set expiration time to 10 minutes from now
                                }
                            }
                        }));
                    })
                    .catch(error => {
                        console.log(error)
                    })
                API.get("WeddingApi", `/pictures/italy/fotobox_preview?id=${guest.id}`)
                    .then(response => {
                        setCachedPreviewUrls(prevState => ({
                            ...prevState,
                            "italy": {
                                ...prevState["italy"],
                                "fotobox": {
                                    data: response,
                                    expiration: new Date().getTime() + 10 * 60 * 1000  // Set expiration time to 10 minutes from now
                                }
                            }
                        }));
                    })
                API.get("WeddingApi", `/pictures/italy/userupload_preview?id=${guest.id}`)
                    .then(response => {
                        setCachedPreviewUrls(prevState => ({
                            ...prevState,
                            "italy": {
                                ...prevState["italy"],
                                "uploads": {
                                    data: response,
                                    expiration: new Date().getTime() + 10 * 60 * 1000  // Set expiration time to 10 minutes from now
                                }
                            }
                        }));
                    })
                    .catch(error => {
                        console.log(error)
                    })
                API.get("WeddingApi", `/pictures/italy-vacation/userupload_preview?id=${guest.id}`)
                    .then(response => {
                        setCachedPreviewUrls(prevState => ({
                            ...prevState,
                            "italy": {
                                ...prevState["italy-vacation"],
                                "uploads": {
                                    data: response,
                                    expiration: new Date().getTime() + 10 * 60 * 1000  // Set expiration time to 10 minutes from now
                                }
                            }
                        }));
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }


    }, [guest, guest.id]);

    useEffect(() => {
        setNewFilesDownloaded(false)
        API.get("WeddingApi", `/pictures/newusercontent?id=${guest.id}`)
            .then(response => {
                setNewUserContent(response)
                setIsLoading(false)
            })
            .catch(error => {
                console.log(error)

            })
    }, [guest.id]);

    useEffect(() => {
        if (modalLocation && newUserContent.unviewedPictures[modalLocation].length === 0) {
            setShowOnlyNewPictures(false)
        }

    }, [modalLocation, newUserContent.unviewedPictures]);

    useEffect(() => {
        API.get("WeddingApi", `/pictures/like?id=${guest.id}`)
            .then(response => {
                setfavoritePictures(response)
            })
            .catch(error => {
                console.log(error)

            })
    }, [guest.id]);


    async function calculateHash(file) {
        const buffer = await file.arrayBuffer();
        const hash = await crypto.subtle.digest('SHA-256', buffer);
        return Array.from(new Uint8Array(hash)).map(b => b.toString(16).padStart(2, '0')).join('');
    }


    function downloadUserContent(eventLocation, delta, favorites) {
        let notificationId = `download-notification-${(new Date()).getTime()}`
        notifications.show({
            id: notificationId,
            loading: true,
            title: 'Lädt',
            message: "Wir holen die Bilder aus dem Album",
            autoClose: false,
            withCloseButton: false,
            color: '#9fbea0'
        });
        let locationName = ""
        if (eventLocation === "cologne") {
            locationName = "Koeln"
        }
        else if (eventLocation === "italy") {
            locationName = "Italien"
        }
        API.get("WeddingApi", `/pictures/${eventLocation}/userupload`, {
            queryStringParameters: {
                id: guest.id,
                delta: delta || false,
                favorites: favorites || false
            }
        })
            .then(url => {
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                console.log(link)
                link.click();
                document.body.removeChild(link)
                notifications.hide(notificationId)
                setNewFilesDownloaded(true)
            })
            .catch(error => {
                console.log(error)
            })
    }
    function downloadPhotographer(eventLocation, favorites) {
        let locationName = ""
        if (eventLocation === "cologne") {
            locationName = "Koeln"
        }
        else if (eventLocation === "italy") {
            locationName = "Italien"
        }

        let notificationId = `download-notification-${(new Date()).getTime()}`
        notifications.show({
            id: notificationId,
            loading: true,
            title: 'Lädt',
            message: "Wir holen die Bilder aus dem Album",
            autoClose: false,
            withCloseButton: false,
            color: '#9fbea0'
        });

        if (modalType === "photographer") {
            API.get("WeddingApi", `/pictures/${eventLocation}/photographer?id=${guest.id}`, {
                queryStringParameters: {
                    id: guest.id,
                    favorites: favorites || false
                }
            })
                .then(url => {
                    const link = document.createElement('a');
                    link.href = url;
                    document.body.appendChild(link);
                    console.log(link)
                    link.click();
                    document.body.removeChild(link)
                    notifications.hide(notificationId)
                })
                .catch(error => {
                    console.log(error)
                })
        }
        else if (modalType === "fotobox") {
            API.get("WeddingApi", `/pictures/${eventLocation}/fotobox?id=${guest.id}`, {
                queryStringParameters: {
                    id: guest.id,
                    favorites: favorites || false
                }
            })
                .then(url => {
                    /*notifications.hide(notificationId)
                    axios.get(url, {
                        responseType: 'blob', // Important
                    }).then(response => {
                        const content = new Blob([response.data], { type: 'application/zip' });
                        let fileName = `Hochzeit-FionaDominik-Fotobox-${locationName}.zip`;
                        if (favorites) {
                            fileName = `Hochzeit-FionaDominik-Fotobox-${locationName}-Favoriten.zip`
                        }
                        saveAs(content, fileName);
                        notifications.hide(notificationId)
                        


                    }).catch(error => {
                        console.error('Error while downloading the file', error);
                    });
                    */
                    const link = document.createElement('a');
                    link.href = url;
                    document.body.appendChild(link);
                    console.log(link)
                    link.click();
                    document.body.removeChild(link)
                    notifications.hide(notificationId)

                })
                .catch(error => {
                    console.log(error)
                })
        }
        else if (modalType === "schoppi") {
            API.get("WeddingApi", `/pictures/${eventLocation}/schoppi?id=${guest.id}`, {
                queryStringParameters: {
                    id: guest.id,
                    favorites: favorites || false
                }
            })
                .then(url => {
                    const link = document.createElement('a');
                    link.href = url;
                    document.body.appendChild(link);
                    console.log(link)
                    link.click();
                    document.body.removeChild(link)
                    notifications.hide(notificationId)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    function showPhotos(eventLocation, type) {
        console.log(eventLocation + "-" + type)
        console.log(cachedPreviewUrls)
        let notificationId = `preview-notification-${(new Date()).getTime()}`
        notifications.show({
            id: notificationId,
            loading: true,
            title: 'Lädt',
            message: "Wir holen die Bilder aus dem Album",
            autoClose: false,
            withCloseButton: false,
            color: '#9fbea0'
        });


        //console.log(new Date(cachedPreviewUrls[eventLocation][type].expiration))
        if (type === "photographer") {
            if (
                cachedPreviewUrls[eventLocation] &&
                cachedPreviewUrls[eventLocation][type] &&
                new Date(cachedPreviewUrls[eventLocation][type].expiration) > new Date()  // Check if the data is not expired
            ) {
                notifications.hide(notificationId)
                setPreviewPictures(cachedPreviewUrls[eventLocation][type].data.sort((a, b) => {
                    return a.originaltimestamp.localeCompare(b.originaltimestamp);
                }))
                setPreviewPictures(cachedPreviewUrls[eventLocation][type].data)
                setModalType("photographer")
                setIsModalPreviewOpen(true)
                setModalLocation(eventLocation)
                console.log("from cache")
            } else {
                API.get("WeddingApi", `/pictures/${eventLocation}/photographer_preview?id=${guest.id}`)
                    .then(response => {
                        notifications.hide(notificationId)
                        setModalType("photographer")
                        setIsModalPreviewOpen(true)
                        setPreviewPictures(response.sort((a, b) => {
                            return a.originaltimestamp.localeCompare(b.originaltimestamp);
                        }))
                        setModalLocation(eventLocation)
                        setCachedPreviewUrls(prevState => ({
                            ...prevState,
                            [eventLocation]: {
                                ...prevState[eventLocation],
                                [type]: {
                                    data: response,
                                    expiration: new Date().getTime() + 10 * 60 * 1000  // Set expiration time to 10 minutes from now
                                }
                            }
                        }));
                    })
                    .catch(error => {
                        console.log(error)
                        notifications.update({
                            id: notificationId,
                            loading: false,
                            title: 'Da ist etwas schief gelaufen',
                            message: "Bitte versuche es nochmal oder melde dich bei uns",
                            icon: <IconX />,
                            autoClose: 5000,
                            withCloseButton: true,
                            color: 'red'
                        });
                    })
            }
        }
        else if (type === "uploads") {
            if (
                cachedPreviewUrls[eventLocation] &&
                cachedPreviewUrls[eventLocation][type] &&
                new Date(cachedPreviewUrls[eventLocation][type].expiration) > new Date()  // Check if the data is not expired
            ) {
                notifications.hide(notificationId)
                setPreviewPictures(cachedPreviewUrls[eventLocation][type].data.sort((a, b) => {
                    return a.originaltimestamp.localeCompare(b.originaltimestamp);
                }));
                setModalType("uploads")
                setIsModalGuestPicturesOpen(true)
                setModalLocation(eventLocation)
            } else {
                API.get("WeddingApi", `/pictures/${eventLocation}/userupload_preview?id=${guest.id}`)
                    .then(response => {
                        notifications.hide(notificationId)
                        setModalType("uploads")
                        setIsModalGuestPicturesOpen(true)
                        setPreviewPictures(response.sort((a, b) => {
                            return a.originaltimestamp.localeCompare(b.originaltimestamp);
                        }))
                        setModalLocation(eventLocation)
                        setCachedPreviewUrls(prevState => ({
                            ...prevState,
                            [eventLocation]: {
                                ...prevState[eventLocation],
                                [type]: {
                                    data: response,
                                    expiration: new Date().getTime() + 10 * 60 * 1000  // Set expiration time to 10 minutes from now
                                }
                            }
                        }));
                    })
                    .catch(error => {
                        console.log(error)
                        notifications.update({
                            id: notificationId,
                            loading: false,
                            title: 'Da ist etwas schief gelaufen',
                            message: "Bitte versuche es nochmal oder melde dich bei uns",
                            icon: <IconX />,
                            autoClose: 5000,
                            withCloseButton: true,
                            color: 'red'
                        });
                    })
            }
        }
        else if (type === "fotobox") {
            if (
                cachedPreviewUrls[eventLocation] &&
                cachedPreviewUrls[eventLocation][type] &&
                new Date(cachedPreviewUrls[eventLocation][type].expiration) > new Date()  // Check if the data is not expired
            ) {
                notifications.hide(notificationId)
                setPreviewPictures(cachedPreviewUrls[eventLocation][type].data.sort((a, b) => {
                    return a.originaltimestamp.localeCompare(b.originaltimestamp);
                }))
                setModalType("fotobox")
                setIsModalPreviewOpen(true)
                setModalLocation(eventLocation)
            } else {
                API.get("WeddingApi", `/pictures/${eventLocation}/fotobox_preview?id=${guest.id}`)
                    .then(response => {
                        notifications.hide(notificationId)
                        setModalType("fotobox")
                        setIsModalPreviewOpen(true)
                        setPreviewPictures(response.sort((a, b) => {
                            return a.originaltimestamp.localeCompare(b.originaltimestamp);
                        }))
                        setModalLocation(eventLocation)
                        setCachedPreviewUrls(prevState => ({
                            ...prevState,
                            [eventLocation]: {
                                ...prevState[eventLocation],
                                [type]: {
                                    data: response,
                                    expiration: new Date().getTime() + 10 * 60 * 1000  // Set expiration time to 10 minutes from now
                                }
                            }
                        }));
                    })
                    .catch(error => {
                        console.log(error)
                        notifications.update({
                            id: notificationId,
                            loading: false,
                            title: 'Da ist etwas schief gelaufen',
                            message: "Bitte versuche es nochmal oder melde dich bei uns",
                            icon: <IconX />,
                            autoClose: 5000,
                            withCloseButton: true,
                            color: 'red'
                        });
                    })
            }

        }
        else if (type === "schoppi") {
            if (
                cachedPreviewUrls[eventLocation] &&
                cachedPreviewUrls[eventLocation][type] &&
                new Date(cachedPreviewUrls[eventLocation][type].expiration) > new Date()  // Check if the data is not expired
            ) {
                notifications.hide(notificationId)
                setPreviewPictures(cachedPreviewUrls[eventLocation][type].data.sort((a, b) => {
                    return a.originaltimestamp.localeCompare(b.originaltimestamp);
                }))
                setModalType("schoppi")
                setIsModalPreviewOpen(true)
                setModalLocation(eventLocation)
            } else {
                API.get("WeddingApi", `/pictures/${eventLocation}/schoppi_preview?id=${guest.id}`)
                    .then(response => {
                        notifications.hide(notificationId)
                        setModalType("schoppi")
                        setIsModalPreviewOpen(true)
                        setPreviewPictures(response.sort((a, b) => {
                            return a.originaltimestamp.localeCompare(b.originaltimestamp);
                        }))
                        setModalLocation(eventLocation)
                        setCachedPreviewUrls(prevState => ({
                            ...prevState,
                            [eventLocation]: {
                                ...prevState[eventLocation],
                                [type]: {
                                    data: response,
                                    expiration: new Date().getTime() + 10 * 60 * 1000  // Set expiration time to 10 minutes from now
                                }
                            }
                        }));
                    })
                    .catch(error => {
                        console.log(error)
                        notifications.update({
                            id: notificationId,
                            loading: false,
                            title: 'Da ist etwas schief gelaufen',
                            message: "Bitte versuche es nochmal oder melde dich bei uns",
                            icon: <IconX />,
                            autoClose: 5000,
                            withCloseButton: true,
                            color: 'red'
                        });
                    })
            }

        }

    }
    async function uploadFiles(files) {
        let notificationId = `upload-notification-${(new Date()).getTime()}`
        notifications.show({
            id: notificationId,
            loading: true,
            title: 'Lädt',
            message:
                <>
                    Wir laden deine Bilder hoch
                    {files.length > 1 ? (
                        <Progress value={0} />
                    ) :
                        ""
                    }
                </>,
            autoClose: false,
            withCloseButton: false,
            color: '#9fbea0'
        });
        // Initialize state variables
        let uploadCount = 0;
        let failCount = 0;
        let totalCount = 0;

        setIsUploading(true);
        for (const file of files) {
            try {
                const hash = await calculateHash(file);

                const response = await API.get('WeddingApi', '/pictures/uploadurl', {
                    queryStringParameters: {
                        fileName: file.name,
                        fileType: file.type,
                        id: guest.id,
                        sha256hash: hash
                    }
                });
                const result = await fetch(response.uploadURL, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        'Content-Type': file.type,
                    },
                });
                if (result.ok) {
                    console.log(`File ${file.name} uploaded successfully!`);
                    uploadCount++; // Increment uploadCount on successful upload
                } else {
                    console.error(`Upload failed for file ${file.name}.`);
                    console.log(result)
                    failCount++; // Increment failCount on failed upload
                }
            } catch (error) {
                if (!error.response || error.response.status !== 409) {
                    console.error('Error uploading file: ', error);
                    failCount++; // Increment failCount on error
                }
            }
            totalCount++
            notifications.update({
                id: notificationId,
                loading: true,
                title: 'Lädt',
                message:
                    <>
                        Wir laden deine Bilder hoch
                        {files.length > 1 ? (
                            <Progress value={(totalCount / files.length * 100)} />
                        ) :
                            ""
                        }
                    </>,
                autoClose: false,
                withCloseButton: false,
                color: '#9fbea0'
            })
        }
        setIsUploading(false);
        if (failCount === 0) {
            notifications.update({
                id: notificationId,
                loading: false,
                autoClose: 2000,
                icon: <IconCheck />,
                message: `${files.length} Bilder wurden hochgeladen`,
                title: "Danke",
                color: '#9fbea0',
            })
        }
        else if (failCount > 0 && uploadCount > 0) {
            notifications.update({
                id: notificationId,
                loading: false,
                autoClose: 5000,
                icon: <IconAlertTriangle />,
                message: `${failCount} von ${files.length} Bilder konnten nicht hochgeladen werden`,
                title: "Danke",
                color: '#FFBF00'
            })
        }
        else if (failCount === files.length) {
            notifications.update({
                id: notificationId,
                loading: false,
                autoClose: 5000,
                icon: <IconAlertTriangle />,
                message: `Leider gab es einen Fehler. Versuche es erneut oder melde dich bei uns`,
                title: "Upps",
                color: 'red'
            })
        }

        // Log or return the counts
        console.log(`Uploaded ${uploadCount} files successfully.`);
        console.log(`${failCount} files failed to upload.`);
    }
    async function dropzoneReject(files) {
        console.log(files)
        let notificationId = `upload-fail-notification-${(new Date()).getTime()}`
        notifications.show({
            id: notificationId,
            loading: false,
            autoClose: 5000,
            icon: <IconAlertTriangle />,
            message: `${files.length} Dateien konnten nicht hochgeladen werden`,
            title: "Danke",
            color: '#FFBF00'
        });
    }
    async function downloadSinglePicture(key) {
        try {
            const response = await API.post('WeddingApi', '/pictures/download', {
                body: { key: key },
                queryStringParameters: {
                    id: guest.id
                }
            });
            const link = document.createElement('a');
            link.href = response.url;
            document.body.appendChild(link);
            console.log(link)
            link.click();
            document.body.removeChild(link)
            //window.open(response.url, '_blank');
            if (key.includes("/userupload/")) {
                setNewFilesDownloaded(true)
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function setFavorite(key, action) {
        try {
            setIsLikeUpdating(true)
            const response = await API.post('WeddingApi', '/pictures/like', {
                body: { key: key, action: action },
                queryStringParameters: {
                    id: guest.id
                }
            });
            setfavoritePictures(response)
            setIsLikeUpdating(false)
            if (showOnlyFavoritesChecked && response[modalType][modalLocation].length === 0) {
                setShowOnlyFavoritesChecked(false)
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function markAllAsSeen() {
        try {

            setViewedPictures((prevPictures) => {
                // Create a copy of prevPictures
                let updatedPictures = [...prevPictures];

                // Iterate over each object in previewPictures
                previewPictures.forEach((picture) => {
                    // If the Key is not present in prevPictures and not present in newUserContent?.viewedPictures[modalLocation]
                    if (!prevPictures.includes(picture.Key) && !newUserContent?.viewedPictures[modalLocation].includes(picture.Key)) {
                        // Add the Key to updatedPictures
                        updatedPictures.push(picture.Key);
                    }
                });

                // Return the updatedPictures
                return updatedPictures;
            });
            setUpdateViewed(true)


        } catch (error) {
            console.error(error);
        }
    }

    async function subscribeNotification(action) {
        try {
            setIsLoadingSubscribing(true)
            setUserInputMailError("")
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (action === false || re.test(String(userInputMail).toLowerCase())) {
                console.log("subscribe")
                const response = await API.post('WeddingApi', '/pictures/subscribe', {
                    body: { subscribe: action, mail: userInputMail },
                    queryStringParameters: {
                        id: guest.id
                    }
                });
                setIsLoadingSubscribing(false)
                setGuestSubscriptionStatus(response.subscribed)
            }
            else {
                setUserInputMailError("Das sieht nach keiner richtigen Email-Adresse aus")
            }

        } catch (error) {
            console.error(error);
        }
    }
    const autoplay = useRef(Autoplay({ delay: 2000 }));
    useEffect(() => {
        if (emblaApiUserPictures) {
            const logSelectedPhotoKey = () => {
                const selectedIndex = emblaApiUserPictures.selectedScrollSnap();
                const selectedPhoto = previewPictures.filter(photo => !showOnlyFavoritesChecked || favoritePictures[modalType][modalLocation].includes(photo.Key))
                    .filter(photo => !showOnlyNewPictures || newUserContent?.unviewedPictures[modalLocation].includes(photo.Key)
                    )[selectedIndex];

                // Add the selected photo to the viewedPictures array
                setViewedPictures((prevPictures) => {
                    if (!prevPictures.includes(selectedPhoto.Key) && !newUserContent?.viewedPictures[modalLocation].includes(selectedPhoto.Key)) {
                        return [...prevPictures, selectedPhoto.Key];
                    } else {
                        return prevPictures;
                    }
                });
            };

            // Add event listener
            emblaApiUserPictures.on('select', logSelectedPhotoKey);

            // Manually trigger event handler for initial picture
            logSelectedPhotoKey();
            // Cleanup
            return () => {
                emblaApiUserPictures.off('select', logSelectedPhotoKey);
            };
        }
    }, [emblaApiUserPictures, previewPictures, showOnlyFavoritesChecked, favoritePictures, modalType, modalLocation, showOnlyNewPictures, newUserContent?.unviewedPictures, newUserContent?.viewedPictures]);

    // Send viewed pictures to API when debouncedViewedPictures value changes
    useEffect(() => {
        const sendViewedPictures = async () => {
            if (debouncedViewedPictures.length > 0) {
                console.log('Sending viewed pictures to API:', debouncedViewedPictures);
                try {
                    const response = await API.post('WeddingApi', '/pictures/viewed', {
                        body: { keys: debouncedViewedPictures },
                        queryStringParameters: {
                            id: guest.id
                        }
                    });
                    if (updateViewed) {
                        API.get("WeddingApi", `/pictures/newusercontent?id=${guest.id}`)
                            .then(response => {
                                setNewUserContent(response)
                                setUpdateViewed(false)

                            })
                            .catch(error => {
                                console.log(error)

                            })
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        };

        sendViewedPictures();
    }, [debouncedViewedPictures, guest.id]);

    useEffect(() => {
        if (emblaApi) {
            emblaApi.on('select', () => {
                setCurrentSlideIndex(emblaApi.selectedScrollSnap());
            });
        }
    }, [emblaApi]);
    useEffect(() => {
        if (emblaApiUserPictures) {
            emblaApiUserPictures.on('select', () => {
                setCurrentSlideIndex(emblaApiUserPictures.selectedScrollSnap());
            });
        }
    }, [emblaApiUserPictures]);

    const handleGalleryClick = (index, item) => {
        console.log(item)
        console.log("HEEER")
    }

    return (

        <>
            <Container align={"center"} mt={"20px"} maw={"900px"}>
                <PictureText guest={guest} />
                {
                    !isLoading ?
                        <Carousel withIndicators loop
                            style={{ flex: 1 }}
                            plugins={[autoplay.current]}
                            onMouseEnter={autoplay.current.stop}
                            onMouseLeave={autoplay.current.reset}
                            maw={"700px"}
                        >
                            {
                                featuredPictures.map(url => (
                                    <Carousel.Slide key={url}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%'
                                        }}>
                                            <Image src={url} />
                                        </div>
                                    </Carousel.Slide>
                                ))
                            }
                        </Carousel>
                        : (
                            <div className='loading-box insite'>
                                <Hearts height={180} width={380} color='#9fbea0' />
                            </div>
                        )
                }
                {guest.attendance ? (
                    <>
                        <Dropzone
                            onDrop={(files) => uploadFiles(files)}
                            onReject={(files) => dropzoneReject(files)}
                            maxSize={50 * 1024 ** 2}
                            accept={IMAGE_MIME_TYPE}
                            mt={"md"}
                        >
                            <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none' }}>
                                <Dropzone.Accept>
                                    <IconUpload
                                        style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                                        stroke={1.5}
                                    />
                                </Dropzone.Accept>
                                <Dropzone.Reject>
                                    <IconX
                                        style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                                        stroke={1.5}
                                    />
                                    <div>
                                        <Text size="xl" inline>
                                            Das sieht leider nicht gut aus.
                                        </Text>
                                        <Text size="sm" c="dimmed" inline mt={7}>
                                            Achte darauf, dass die Datei ein Foto ist und unter 10MB groß ist.
                                        </Text>
                                    </div>
                                </Dropzone.Reject>
                                <Dropzone.Idle>
                                    <IconPhoto
                                        style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }}
                                        stroke={1.5}
                                    />
                                    <div>
                                        <Text size="xl" inline>
                                            Lade hier deine Fotos hoch.
                                        </Text>
                                        <Text size="sm" c="dimmed" inline mt={7}>
                                            Wir schauen nochmal drüber und stellen sie dann ggf. den anderen bereit.
                                        </Text>
                                    </div>
                                </Dropzone.Idle>
                            </Group>
                        </Dropzone>
                        <Paper
                            withBorder shadow="sm" radius="md" mt={"md"} mb={"md"}
                        >
                            <Box p={"sm"} style={{ "borderBottom": "calc(0.0625rem * var(--mantine-scale)) solid", "borderColor": "var(--mantine-color-gray-3)" }}>
                                <Group justify="center">
                                    <Text fw={500}>Standesamtliche Trauung</Text>
                                </Group>
                                <Text c="dimmed" size="sm">
                                    Hier findest du alle Fotos aus Köln
                                </Text>
                            </Box>
                            <Group m={"sm"} justify='center'>
                                <Group justify='center' className='button-group'>
                                    <Button color='#9fbea0' onClick={() => showPhotos("cologne", "photographer")}>Fotos von PhotoMo</Button>
                                    <Button color='#9fbea0' onClick={() => showPhotos("cologne", "fotobox")}>Fotos aus der Fotobox</Button>
                                    <Indicator color='red' inline label={newUserContent?.unviewedPictures?.cologne?.length} size={"20"} disabled={!newUserContent?.unviewedPictures?.cologne?.length || newUserContent?.unviewedPictures?.cologne.length === 0 || newUserContent?.totalCount?.cologne === 0}>
                                        <Button disabled={newUserContent?.totalCount?.cologne === 0} color='#9fbea0' onClick={() => showPhotos("cologne", "uploads")}>Eure Fotos</Button>
                                    </Indicator>
                                </Group>
                            </Group>
                        </Paper>
                        {
                            guest?.invitedEvents?.italy === true ? (
                                <>
                                    <Paper
                                        withBorder shadow="sm" radius="md" mt={"md"} mb={"md"}
                                    >
                                        <Box p={"sm"} style={{ "borderBottom": "calc(0.0625rem * var(--mantine-scale)) solid", "borderColor": "var(--mantine-color-gray-3)" }}>
                                            <Group justify="center">
                                                <Text fw={500}>Freie Trauung</Text>
                                            </Group>
                                            <Text c="dimmed" size="sm">
                                                Hier findest du alle Fotos der freien Trauung
                                            </Text>
                                        </Box>
                                        <Group m={"sm"} justify='center'>
                                            <Group justify='center' className='button-group'>
                                                <Button color='#9fbea0' onClick={() => showPhotos("italy", "photographer")}>Fotos der Fotografin</Button>
                                                <Button color='#9fbea0' onClick={() => showPhotos("italy", "schoppi")}>Fotos von Schoppi</Button>
                                                <Button color='#9fbea0' onClick={() => showPhotos("italy", "fotobox")}>Fotos aus der Fotobox</Button>
                                                <Indicator color='red' inline label={newUserContent?.unviewedPictures?.italy?.length} size={"20"} disabled={!newUserContent?.unviewedPictures?.italy?.length || newUserContent?.unviewedPictures?.italy?.length === 0 || newUserContent?.totalCount?.italy === 0}>
                                                    <Button disabled={newUserContent?.totalCount?.italy === 0} color='#9fbea0' onClick={() => showPhotos("italy", "uploads")}>Eure Fotos</Button>
                                                </Indicator>

                                            </Group>
                                        </Group>
                                    </Paper>
                                    <Paper
                                        withBorder shadow="sm" radius="md" mt={"md"} mb={"md"}
                                    >
                                        <Box p={"sm"} style={{ "borderBottom": "calc(0.0625rem * var(--mantine-scale)) solid", "borderColor": "var(--mantine-color-gray-3)" }}>
                                            <Group justify="center">
                                                <Text fw={500}>Urlaub in Italien</Text>
                                            </Group>
                                            <Text c="dimmed" size="sm">
                                                Hier findest du alle Fotos aus unserem Urlaub!
                                            </Text>
                                        </Box>
                                        <Group m={"sm"} justify='center'>
                                            <Group justify='center' className='button-group'>
                                                <Indicator color='red' inline label={newUserContent?.unviewedPictures["italy-vacation"].length} size={"20"} disabled={!newUserContent?.unviewedPictures["italy-vacation"].length || newUserContent?.unviewedPictures["italy-vacation"].length === 0 || newUserContent?.totalCount["italy-vacation"] === 0}>
                                                    <Button disabled={newUserContent?.totalCount["italy-vacation"] === 0} color='#9fbea0' onClick={() => showPhotos("italy-vacation", "uploads")}>Eure Fotos</Button>
                                                </Indicator>
                                            </Group>
                                        </Group>
                                    </Paper>
                                </>
                            )

                                : (null)
                        }
                        <Modal
                            opened={isModalPreviewOpen}
                            onClose={() => {
                                setIsModalPreviewOpen(false);
                                setShowOnlyFavoritesChecked(false);
                                setCurrentSlideIndex(0)
                            }}
                            title=""
                            fullScreen
                            padding={0}
                        >
                            <Container align={"center"}
                                pl={0}
                                pr={0}
                            >
                                <Group justify='space-between' display={"flex"} maw={"95%"}>
                                    <Group>
                                        <Switch checked={showOnlyFavoritesChecked}
                                            onChange={(event) => setShowOnlyFavoritesChecked(event.currentTarget.checked)}
                                            color='#9fbea0'
                                            label="Nur Favoriten anzeigen"
                                            disabled={favoritePictures[modalType][modalLocation]?.length === 0 || false}
                                            pl={0}
                                        />
                                    </Group>
                                    <Group justify='center' display={'flex'} pr={0} pl={0}>
                                        <LuGalleryThumbnails
                                            color={
                                                isGalleryModeActive === false ? ("9fbea0") : ("black")
                                            }
                                            onClick={() => setIsGalleryModeActive(false)}
                                            size={
                                                isGalleryModeActive === false ? (30) : (25)
                                            }
                                        />
                                        <RiGalleryView
                                            color={
                                                isGalleryModeActive === true ? ("9fbea0") : ("black")
                                            }
                                            onClick={() => setIsGalleryModeActive(true)}
                                            size={
                                                isGalleryModeActive === true ? (30) : (25)
                                            }

                                        />
                                    </Group>

                                </Group>
                                {
                                    isGalleryModeActive === false ? (
                                        <Carousel loop mt={10} getEmblaApi={setEmbla}>
                                            {previewPictures.filter(photo => !showOnlyFavoritesChecked || favoritePictures[modalType][modalLocation]?.includes(photo.Key))
                                                .map((photo, index, array) => (
                                                    <Carousel.Slide key={index} mb={10}>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: '100%'
                                                        }}>
                                                            <Paper
                                                                shadow="md"
                                                                p="md"
                                                                radius="md"
                                                                maw={"95%"}
                                                                pl={0}
                                                                pr={0}
                                                            >


                                                                {
                                                                    (Math.abs(currentSlideIndex - index) <= 5 || Math.abs(currentSlideIndex - index) >= array.length - 5) ? (
                                                                        <Image
                                                                            src={photo.thumbnailUrl} // Assuming photo is the URL of the image
                                                                            alt={`Slide ${index}`}
                                                                            maw={"600px"}
                                                                            mah={"80vh"}
                                                                        />
                                                                    ) : (
                                                                        <Hearts /> // Render your spinner component here
                                                                    )}
                                                                <Group mt={10} justify='center'>
                                                                    {
                                                                        !isLikeUpdating ? (
                                                                            favoritePictures[modalType][modalLocation].includes(photo.Key) ? (
                                                                                <FaHeart onClick={() => setFavorite(photo.Key, "remove")} color='#9fbea0' size={30} />

                                                                            ) : (
                                                                                <FaRegHeart onClick={() => setFavorite(photo.Key, "add")} size={30} />

                                                                            )
                                                                        ) : (
                                                                            <Loader color='#9fbea0' size={"xs"} />
                                                                        )

                                                                    }
                                                                    <IconDownload onClick={() => downloadSinglePicture(photo.Key)} size={30} />
                                                                </Group>
                                                                {
                                                                    modalLocation === "cologne" && modalType === "photographer" ? (
                                                                        <Group mt={10} justify='center'>
                                                                            <a
                                                                                href='https://www.instagram.com/photomo_pictures'
                                                                                target="_blank"
                                                                                style={{ display: 'flex', alignItems: 'center', marginLeft: '2.5%' }} rel="noreferrer"
                                                                            >
                                                                                <IconBrandInstagram style={{ marginRight: '2px' }} />
                                                                                <Text size='sm'>@photomo_pictures</Text>
                                                                            </a>
                                                                        </Group>
                                                                    ) :
                                                                        (
                                                                            modalType === "schoppi" ? (
                                                                                <Group mt={10} justify='center'>
                                                                                    <a
                                                                                        href='https://www.instagram.com/chrissi_sch'
                                                                                        target="_blank"
                                                                                        style={{ display: 'flex', alignItems: 'center', marginLeft: '2.5%' }} rel="noreferrer"
                                                                                    >
                                                                                        <IconBrandInstagram style={{ marginRight: '2px' }} />
                                                                                        <Text size='sm'>@chrissi_sch</Text>
                                                                                    </a>
                                                                                </Group>
                                                                            ) : (null)
                                                                        )
                                                                }
                                                            </Paper>
                                                        </div>
                                                    </Carousel.Slide>
                                                ))}

                                        </Carousel>
                                    ) : (null)}

                                {
                                    isGalleryModeActive === true ? (
                                        <>
                                            <Paper
                                                shadow="md"
                                                p="md"
                                                radius="md"
                                                maw={"95%"}
                                                //pl={0}
                                                //pr={0}
                                                m={"auto"}
                                            >
                                                <Gallery images={
                                                    previewPictures.filter(photo => !showOnlyFavoritesChecked || favoritePictures[modalType][modalLocation]?.includes(photo.Key))
                                                        .map(pic => ({
                                                            src: pic.thumbnailUrl,
                                                            Key: pic.Key
                                                        }))
                                                }
                                                    onClick={(index, item) => {
                                                        setIsGalleryModalOpen(true);
                                                        setGallerySelectedPicture(item)
                                                        console.log(item)
                                                    }}
                                                    enableImageSelection={false}
                                                    thumbnailImageComponent={LazyLoadingImage}
                                                />
                                            </Paper>
                                            <Modal opened={isGalleryModalOpen}
                                                onClose={() => {
                                                    setIsGalleryModalOpen(false);
                                                }}
                                                padding={0}
                                            >

                                                <Paper
                                                    shadow="md"
                                                    p="md"
                                                    pt={0}
                                                    radius="md"
                                                    maw={"95%"}
                                                    //pl={0}
                                                    //pr={0}
                                                    m={"auto"}
                                                    mt={0}
                                                    mb={"md"}
                                                >


                                                    <Image
                                                        src={gallerySelectedPicture.src} // Assuming photo is the URL of the image
                                                        maw={"600px"}
                                                        mah={"80vh"}
                                                        p={"0"}
                                                    />

                                                    <Group mt={10} justify='center'>
                                                        {console.log(modalLocation)}
                                                        {
                                                            !isLikeUpdating ? (
                                                                favoritePictures[modalType][modalLocation].includes(gallerySelectedPicture.Key) ? (
                                                                    <FaHeart onClick={() => setFavorite(gallerySelectedPicture.Key, "remove")} color='#9fbea0' size={30} />

                                                                ) : (
                                                                    <FaRegHeart onClick={() => setFavorite(gallerySelectedPicture.Key, "add")} size={30} />

                                                                )
                                                            ) : (
                                                                <Loader color='#9fbea0' size={"xs"} />
                                                            )

                                                        }
                                                        <IconDownload onClick={() => downloadSinglePicture(gallerySelectedPicture.Key)} size={30} />
                                                    </Group>
                                                    {
                                                        modalLocation === "cologne" && modalType === "photographer" ? (
                                                            <Group mt={10} justify='center'>
                                                                <a
                                                                    href='https://www.instagram.com/photomo_pictures'
                                                                    target="_blank"
                                                                    style={{ display: 'flex', alignItems: 'center', marginLeft: '2.5%' }} rel="noreferrer"
                                                                >
                                                                    <IconBrandInstagram style={{ marginRight: '2px' }} />
                                                                    <Text size='sm'>@photomo_pictures</Text>
                                                                </a>
                                                            </Group>
                                                        ) :
                                                            (
                                                                modalType === "schoppi" ? (
                                                                    <Group mt={10} justify='center'>
                                                                        <a
                                                                            href='https://www.instagram.com/chrissi_sch'
                                                                            target="_blank"
                                                                            style={{ display: 'flex', alignItems: 'center', marginLeft: '2.5%' }} rel="noreferrer"
                                                                        >
                                                                            <IconBrandInstagram style={{ marginRight: '2px' }} />
                                                                            <Text size='sm'>@chrissi_sch</Text>
                                                                        </a>
                                                                    </Group>
                                                                ) : (null)
                                                            )
                                                    }
                                                </Paper>
                                            </Modal>
                                        </>
                                    ) : (null)}

                                <Group justify='center' mt={10} pl={"md"} pr={"md"}>
                                    <Button disabled={favoritePictures[modalType][modalLocation]?.length === 0 || false}
                                        color='#9fbea0' onClick={() => downloadPhotographer(modalLocation, true)}>{favoritePictures[modalType][modalLocation]?.length || null} Favoriten herunterladen</Button>
                                    <Button color='#9fbea0' onClick={() => downloadPhotographer(modalLocation)}>Alle Fotos herunterladen</Button>
                                </Group>
                            </Container>
                        </Modal>
                        <Modal
                            opened={isModalGuestPicturesOpen}
                            onClose={() => {
                                setIsModalGuestPicturesOpen(false);
                                setShowOnlyFavoritesChecked(false);
                                setShowOnlyNewPictures(false);
                            }}
                            title=""
                            fullScreen
                            padding={0}
                        >
                            <Container align={"center"}
                                pl={0}
                                pr={0}
                            >
                                <Group justify='space-between' maw={"95%"}>
                                    <Group>
                                        <Switch checked={showOnlyFavoritesChecked}
                                            onChange={(event) => {
                                                setShowOnlyFavoritesChecked(event.currentTarget.checked);
                                                setShowOnlyNewPictures(false)
                                            }}
                                            color='#9fbea0'
                                            label="Nur Favoriten anzeigen"
                                            disabled={favoritePictures[modalType][modalLocation]?.length === 0 || false}
                                            pl={0}
                                        />
                                        <Switch checked={showOnlyNewPictures}
                                            onChange={(event) => {
                                                setShowOnlyNewPictures(event.currentTarget.checked);
                                                setShowOnlyFavoritesChecked(false)
                                            }}
                                            color='#9fbea0'
                                            label="Nur neue Bilder anzeigen"
                                            disabled={newUserContent?.unviewedPictures[modalLocation]?.length === 0 || false}
                                            pl={0}
                                        />
                                    </Group>
                                    <Group justify='center' display={'flex'} pr={0} pl={0}>
                                        <LuGalleryThumbnails
                                            color={
                                                isGalleryModeActive === false ? ("9fbea0") : ("black")
                                            }
                                            onClick={() => setIsGalleryModeActive(false)}
                                            size={
                                                isGalleryModeActive === false ? (30) : (25)

                                            }

                                        />
                                        <RiGalleryView
                                            color={
                                                isGalleryModeActive === true ? ("9fbea0") : ("black")
                                            }
                                            onClick={() => setIsGalleryModeActive(true)}
                                            size={
                                                isGalleryModeActive === true ? (30) : (25)
                                            }

                                        />
                                    </Group>
                                </Group>
                                {
                                    isGalleryModeActive === false ? (
                                        <Carousel loop style={{ flex: 1 }} getEmblaApi={setEmblaUserPictures}>
                                            {previewPictures.filter(photo => !showOnlyFavoritesChecked || favoritePictures[modalType][modalLocation].includes(photo.Key))
                                                .filter(photo => !showOnlyNewPictures || newUserContent?.unviewedPictures[modalLocation].includes(photo.Key))
                                                .map((photo, index, array) => (
                                                    <Carousel.Slide key={index}>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: '100%'
                                                        }}>
                                                            <Paper
                                                                shadow="md"
                                                                p="md"
                                                                radius="md"
                                                                maw={"95%"}
                                                                pl={0}
                                                                pr={0}
                                                            >
                                                                <Indicator color='red' inline label={"neu"} size={"20"} disabled={!newUserContent?.unviewedPictures[modalLocation].includes(photo.Key)} offset={10}>

                                                                    {
                                                                        (Math.abs(currentSlideIndex - index) <= 5 || Math.abs(currentSlideIndex - index) >= array.length - 5) ? (
                                                                            <Image
                                                                                src={photo.thumbnailUrl} // Assuming photo is the URL of the image
                                                                                alt={`Slide ${index}`}
                                                                                maw={"600px"}
                                                                                mah={"80vh"}
                                                                            />
                                                                        ) : (
                                                                            <Hearts /> // Render your spinner component here
                                                                        )}
                                                                </Indicator>
                                                                <Group mt={10} justify='center'>
                                                                    {
                                                                        !isLikeUpdating ? (
                                                                            favoritePictures[modalType][modalLocation].includes(photo.Key) ? (
                                                                                <FaHeart onClick={() => setFavorite(photo.Key, "remove")} color='#9fbea0' size={30} />

                                                                            ) : (
                                                                                <FaRegHeart onClick={() => setFavorite(photo.Key, "add")} size={30} />

                                                                            )
                                                                        ) : (
                                                                            <Loader color='#9fbea0' size={"xs"} />
                                                                        )
                                                                    }
                                                                    <IconDownload onClick={() => downloadSinglePicture(photo.Key)} size={30} />
                                                                </Group>
                                                                <Group mt={10} justify='center'>

                                                                    <Text size='sm'>{photo.uploadername}</Text>
                                                                </Group>
                                                            </Paper>
                                                        </div>
                                                    </Carousel.Slide>
                                                ))}

                                        </Carousel>
                                    ) : (null)
                                }
                                {
                                    isGalleryModeActive === true ? (
                                        <>
                                            <Paper
                                                shadow="md"
                                                p="md"
                                                radius="md"
                                                maw={"95%"}
                                                //pl={0}
                                                //pr={0}
                                                m={"auto"}
                                            >

                                                <Gallery images={
                                                    previewPictures.filter(photo => !showOnlyFavoritesChecked || favoritePictures[modalType][modalLocation].includes(photo.Key))
                                                        .filter(photo => !showOnlyNewPictures || newUserContent?.unviewedPictures[modalLocation].includes(photo.Key))
                                                        .map(pic => ({
                                                            src: pic.thumbnailUrl,
                                                            Key: pic.Key,
                                                            uploadername: pic.uploadername,
                                                            new: newUserContent?.unviewedPictures[modalLocation].includes(pic.Key)
                                                        }))
                                                }
                                                    onClick={(index, item) => {
                                                        setIsGalleryModalOpen(true);
                                                        setGallerySelectedPicture(item)
                                                        // Add the selected photo to the viewedPictures array
                                                        setViewedPictures((prevPictures) => {
                                                            if (!prevPictures.includes(item.Key) && !newUserContent?.viewedPictures[modalLocation].includes(item.Key)) {
                                                                return [...prevPictures, item.Key];
                                                            } else {
                                                                return prevPictures;
                                                            }
                                                        });
                                                        console.log(item)
                                                    }}
                                                    enableImageSelection={false}
                                                    thumbnailImageComponent={LazyLoadingImage}
                                                />
                                            </Paper>
                                            <Modal opened={isGalleryModalOpen}
                                                onClose={() => {
                                                    setIsGalleryModalOpen(false);
                                                }}
                                                padding={0}
                                            >

                                                <Paper
                                                    shadow="md"
                                                    p="md"
                                                    pt={0}
                                                    radius="md"
                                                    maw={"95%"}
                                                    //pl={0}
                                                    //pr={0}
                                                    m={"auto"}
                                                    mt={0}
                                                    mb={"md"}
                                                >


                                                    <Image
                                                        src={gallerySelectedPicture.src} // Assuming photo is the URL of the image
                                                        maw={"600px"}
                                                        mah={"80vh"}
                                                        p={"0"}
                                                    />

                                                    <Group mt={10} justify='center'>
                                                        {console.log(modalLocation)}
                                                        {
                                                            !isLikeUpdating ? (
                                                                favoritePictures[modalType][modalLocation].includes(gallerySelectedPicture.Key) ? (
                                                                    <FaHeart onClick={() => setFavorite(gallerySelectedPicture.Key, "remove")} color='#9fbea0' size={30} />

                                                                ) : (
                                                                    <FaRegHeart onClick={() => setFavorite(gallerySelectedPicture.Key, "add")} size={30} />

                                                                )
                                                            ) : (
                                                                <Loader color='#9fbea0' size={"xs"} />
                                                            )

                                                        }
                                                        <IconDownload onClick={() => downloadSinglePicture(gallerySelectedPicture.Key)} size={30} />
                                                    </Group>
                                                    <Group mt={10} justify='center'>

                                                        <Text size='sm'>{gallerySelectedPicture.uploadername}</Text>
                                                    </Group>
                                                </Paper>
                                            </Modal>
                                        </>
                                    ) : (null)}

                                <Group justify='center' mt={"sm"} mb={"sm"} pl={"md"} pr={"md"}>
                                    <Button disabled={favoritePictures[modalType][modalLocation]?.length === 0 || false}
                                        color='#9fbea0' onClick={() => downloadUserContent(modalLocation, false, true)}>{favoritePictures[modalType][modalLocation]?.length || null} Favoriten herunterladen</Button>
                                    <Button disabled={!newUserContent?.unviewedPictures[modalLocation] || newUserContent?.unviewedPictures[modalLocation].length === 0} color='#9fbea0' onClick={() => { downloadUserContent(modalLocation, true, false); }}>Nur neue Bilder herunterladen</Button>
                                    <Button variant='outline' color='#9fbea0' onClick={() => downloadUserContent(modalLocation, false, false)}>Alle herunterladen</Button>
                                    {
                                        isGalleryModeActive === true ? (
                                            <Button variant='outline' disabled={!newUserContent?.unviewedPictures[modalLocation] || newUserContent?.unviewedPictures[modalLocation].length === 0} color='#9fbea0' onClick={() => { markAllAsSeen() }}>Alle als gesehen markieren</Button>
                                        ) : (null)
                                    }

                                </Group>
                            </Container>
                        </Modal>
                        {/*
                <Card withBorder shadow="sm" radius="md" mt={"md"} mb={"md"}>
                    <Card.Section withBorder inheritPadding py="xs">
                        <Group justify="center">
                            <Text fw={500}>Fotos der Fotografen</Text>
                        </Group>
                        <Text c="dimmed" size="sm">
                            Lade hier die professionellen Fotos heruter
                        </Text>
                    </Card.Section>
                    <Card.Section withBorder p={"sm"}>
                        <Group justify='center'>

                            <Button color='#9fbea0' onClick={() => showPhotos("cologne", "photographer")}>Fotos aus Köln anschauen</Button>
                            {
                                guest?.invitedEvents?.italy === true ?
                                    <Button color='#9fbea0' onClick={() => showPhotos("italy", "photographer")}>Fotos aus der Toskana anschauen</Button>

                                    : (null)
                            }
                        </Group>
                    </Card.Section>
                </Card>
                <Card withBorder shadow="sm" radius="md" mt={"md"} mb={"md"}>
                    <Card.Section withBorder inheritPadding py="xs">
                        <Group justify="center">
                            <Text fw={500}>Fotos aus der Fotobox</Text>
                        </Group>
                        <Text c="dimmed" size="sm">
                            Hier gibt es die Fotos aus der Fotobox
                        </Text>
                    </Card.Section>
                    <Card.Section withBorder p={"sm"}>
                        <Group justify='center'>

                            <Button color='#9fbea0' onClick={() => showPhotos("cologne", "fotobox")}>Fotos aus Köln anschauen</Button>
                            {
                                guest?.invitedEvents?.italy === true ?
                                    <Button color='#9fbea0' onClick={() => showPhotos("italy", "fotobox")}>Fotos aus der Toskana anschauen</Button>

                                    : (null)
                            }
                        </Group>
                    </Card.Section>
                </Card>

                <Card withBorder shadow="sm" radius="md" mt={"md"} mb={"md"}>
                    <Card.Section withBorder inheritPadding py="xs">
                        <Group justify="center">
                            <Text fw={500}>Fotos der Gäste</Text>
                        </Group>
                        <Text c="dimmed" size="sm">
                            Wir sammeln gerade eure Fotos und werden sie euch bald zur Verfügung stellen
                        </Text>
                    </Card.Section>
                    <Card.Section withBorder p={"sm"}>
                        <Group justify='center'>
                            {/* 
                            <Indicator inline label={newUserContent?.undownloadedCount?.cologne} size={"25"} disabled={!newUserContent?.undownloadedCount?.cologne || newUserContent?.undownloadedCount?.cologne === 0}>
                                <Button color='#9fbea0' onClick={() => downloadZip("cologne")}>Fotos aus Köln herunterladen</Button>
                            </Indicator>
                            {
                                guest?.invitedEvents?.italy === true ?
                                    <Indicator inline label={newUserContent?.undownloadedCount?.italy} size={"20"} disabled={!newUserContent?.undownloadedCount?.italy || newUserContent?.undownloadedCount?.italy === 0}>
                                        <Button color='#9fbea0' onClick={() => downloadZip("italy")}>Fotos aus der Toskana herunterladen</Button>
                                    </Indicator>
                                    : (null)
                            }
                                    
                                    
                            <Indicator color='red' inline label={newUserContent?.unviewedPictures?.cologne?.length} size={"20"} disabled={!newUserContent?.unviewedPictures?.cologne?.length || newUserContent?.unviewedPictures?.cologne.length === 0 || newUserContent?.totalCount?.cologne === 0}>
                                <Button disabled={newUserContent?.totalCount?.cologne === 0} color='#9fbea0' onClick={() => showPhotos("cologne", "uploads")}>Fotos aus Köln anschauen</Button>
                            </Indicator>
                            {
                                guest?.invitedEvents?.italy === true ?
                                    <Indicator color='red' inline label={newUserContent?.unviewedPictures?.italy?.length} size={"20"} disabled={!newUserContent?.unviewedPictures?.italy?.length || newUserContent?.unviewedPictures?.italy?.length === 0 || newUserContent?.totalCount?.italy === 0}>
                                        <Button disabled={newUserContent?.totalCount?.italy === 0} color='#9fbea0' onClick={() => showPhotos("italy", "uploads")}>Fotos aus der Toskana anschauen</Button>
                                    </Indicator>
                                    : (null)
                            }
                        </Group>
                        <Card.Section withBorder inheritPadding py="xs" mt={"md"} p={"sm"}>
                            <Text size="sm">
                                Wenn du möchtest, benachrichtigen wir dich, wenn es mindestens 10 neue Fotos gibt
                            </Text>
                            {
                                !guestSubscriptionStatus ? (
                                    <Group mt={"sm"} justify='center'>
                                        <TextInput w={"75%"}
                                            value={userInputMail}
                                            onChange={(event) => setUserInputMail(event.currentTarget.value)}
                                            placeholder="Lass uns deine Mailadresse da"
                                            type='email'
                                            error={userInputMailError}
                                        />
                                        <IconCheck onClick={() => subscribeNotification("true")} />
                                    </Group>
                                ) :
                                    (
                                        <Button variant='outline' color='#9fbea0' onClick={() => subscribeNotification(false)}>Abmelden</Button>

                                    )
                            }


                        </Card.Section>
                    </Card.Section>
                </Card>
                */}
                        <Paper
                            withBorder shadow="sm" radius="md" mt={"md"} mb={"md"}

                        >
                            <Box p={"sm"} style={{ "borderBottom": "calc(0.0625rem * var(--mantine-scale)) solid", "borderColor": "var(--mantine-color-gray-3)" }}>
                                <Group justify="center">
                                    <Text fw={500}>Unser Service</Text>
                                </Group>
                                <Text c="dimmed" size="sm">
                                    Wenn du möchstest schicken wir dir gerne eine Email wenn neue Fotos hochgeladen wurden
                                </Text>
                            </Box>
                            {
                                !guestSubscriptionStatus ? (
                                    <Group m={"sm"} justify='center'>
                                        <TextInput w={"60%"}
                                            value={userInputMail}
                                            onChange={(event) => setUserInputMail(event.currentTarget.value)}
                                            placeholder="Deine Mailadresse"
                                            type='email'
                                            error={userInputMailError}
                                        />
                                        {
                                            !isLoadingSubscribing ? (
                                                <Button variant='outline' color='#9fbea0' onClick={() => subscribeNotification(true)}>Anmelden</Button>
                                            ) : (
                                                <Loader color='#9fbea0' size={"xs"} />
                                            )
                                        }
                                    </Group>
                                ) :
                                    (
                                        <Group m={"sm"} justify='center'>
                                            {
                                                !isLoadingSubscribing ? (
                                                    <Button variant='outline' color='#9fbea0' onClick={() => subscribeNotification(false)}>Abmelden</Button>
                                                ) :
                                                    (
                                                        <Loader color='#9fbea0' size={"xs"} />

                                                    )
                                            }
                                        </Group>
                                    )
                            }
                        </Paper>


                    </>
                ) : (null)}
            </Container >
        </>
    );
}



export default PictureOutletCombined;
