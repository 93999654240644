import { Box, Container, Text } from "@mantine/core";
export function PictureText({ guest }) {
    const salutationString = guest.salutation.split(",")
    return (

        <Container p={0} ta={"left"}>
            <Box>
                <strong>
                    {salutationString.map((salutation, index) => (
                        <Box key={index}>
                            {salutation + ","}
                            <br />
                        </Box>

                    ))}
                </strong>
            </Box>
            {
                <p>
                    <Text>
                        {guest?.thanktext ? (
                            guest.thanktext.split("<p>").map(text =>(
                                <p>
                                    {text.trim()}
                                </p>
                            ))
                        ) : (
                            guest?.allowedTotalGuests > 1 ? (
                                <>
                                    <p>
                                        unsere Hochzeit war für uns einfach nur traumhaft schön und wir sind sehr glücklich, dass auch Ihr ein Teil davon wart! Danke für die lieben Worte, die tollen Geschenke und die vielen schönen Momente mit Euch.
                                    </p>
                                    <p>
                                        Damit auch Ihr die Möglichkeit habt diese Zeit Revue passieren zu lassen, haben wir unten für Euch unsere Fotos zum Download bereitgestellt.
                                    </p>
                                    <p>
                                        Natürlich möchten wir auch wissen, wie Ihr diesen Tag erlebt habt und freuen uns, wenn Ihr Eure Fotos hier auf der Website zur Verfügung stellt. Dann haben wir alle kleinen und großen unvergesslichen Momente gesammelt an einem Ort.
                                    </p>
                                    <p>
                                        Wir hoffen, wir sehen uns schon ganz bald wieder!
                                    </p>
                                    <p>
                                        Fiona & Dominik Brökers
                                    </p>
                                </>
                            )
                                : (
                                    <>
                                        <p>
                                            unsere Hochzeit war für uns einfach nur traumhaft schön und wir sind sehr glücklich, dass auch Du ein Teil davon warst! Danke für die lieben Worte, die tollen Geschenke und die vielen schönen Momente Dir.
                                        </p>
                                        <p>
                                            Damit auch Du die Möglichkeit hast diese Zeit Revue passieren zu lassen, haben wir unten für Dich unsere Fotos zum Download bereitgestellt.
                                        </p>
                                        <p>
                                            Natürlich möchten wir auch wissen, wie Du diesen Tag erlebt hast und freuen uns, wenn Du Deine Fotos hier auf der Website zur Verfügung stellst. Dann haben wir alle kleinen und großen unvergesslichen Momente gesammelt an einem Ort.
                                        </p>
                                        <p>
                                            Wir hoffen, wir sehen uns schon ganz bald wieder!
                                        </p>
                                        <p>
                                            Fiona & Dominik Brökers
                                        </p>

                                    </>
                                )
                        )
                        }
                    </Text >
                </p >
            }

        </Container >
    );
}
