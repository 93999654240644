import React from 'react';
import ScheduleActivity from '../fragments/ScheduleActivity';
import Heading from '../fragments/Heading';
import { useState } from 'react';
import {FaChevronDown} from "react-icons/fa";
const AccordionItem = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="accordion-item">
        <div className="accordion-header" onClick={() => setIsOpen(!isOpen)}>
        <span className={`arrow ${isOpen ? 'open' : ''}`}><FaChevronDown/></span>
          <div className='title'>
          <p>{title}</p>
        
          </div>
        </div>
        {isOpen && <div className="accordion-content">{content}</div>}
      </div>
    );
  };

function FAQ({ faqArray }) {
    return (
        <>
            <section className="section faq-section" id="faq">
                <Heading heading={"FAQ"} subheading={"Hier findet ihr Informationen"} />
                <div className="container">
                    <ul className="items">
                        {faqArray ? (

                            faqArray.map((element, index) => (
                                <AccordionItem
                                    title={element.title}
                                    content={element.description}
                                    key={index}
                                />
                            ))
                        ) : null}

                        {/* Repeat the ScheduleActivity component for other activities */}
                    </ul>
                </div>
            </section>
        </>
    );
}



export default FAQ;
