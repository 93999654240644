import '@mantine/core/styles.css';
import '@mantine/core/styles.layer.css';
import '@mantine/notifications/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/dropzone/styles.css';

import 'mantine-datatable/styles.layer.css';
import './App.css';
import './styles/styles.css'
import './styles/morestyles.css'
import './styles/navbar.css'
import './styles/navbarEvents.css'
import './styles/rsvp.css'
import './styles/faq.css'
import './styles/organizers.css'
import './styles/header.css'
import './styles/schedule.css'
import './styles/pictures.css'




import React, { useState, useEffect } from 'react';
import Cologne from './pages/Cologne';
import Italy from './pages/ItalyDetail';
import ItalyFAQ from './components/italy/ItalyFAQ';
import InvitationPage from './pages/InvitationPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { API } from 'aws-amplify';
import { guestDataContext } from './contexts/GuestDataContext';
import Schedule from './components/italy/Schedule';
import EventInfo from './components/EventInfo';
import Groceries from './components/italy/Groceries';
import Pictures from './pages/Pictures';
import PictureOutlet from './components/pictures/PictureOutlet';
import PictureOutletCombined from './components/pictures/PictureOutletCombined';
function App() {


  const [guestData, setGuestData] = useState(null);
  const [rsvp, setRsvp] = useState("init");

  useEffect(() => {
    // Parse the URL and extract the 'id' parameter
    const queryParams = new URLSearchParams(window.location.search);
    var id = null;
    if (queryParams.get('id')) {
      id = queryParams.get('id');
      localStorage.setItem('userid', id);

    } else if (localStorage.getItem('userid')) {
      id = localStorage.getItem('userid');
    } else {

    }
    if (id === 'SCojyLsXcp' && (window.location.hostname.endsWith("fiona-und-dominik.de") || window.location.hostname === "localhost")) {
      window.location.replace(`https://fiona--und--dominik-de.translate.goog/?id=${id}&_x_tr_sl=de&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp`)
    }
    if (id) {
      API.get("WeddingApi", "/guest/" + id)
        .then(response => {
          let guestData = {
            "status": "success",
            ...response
          }
          setGuestData(guestData);
        })
        .catch(error => {
          if (error.response.status === 404) {
            let guestData = {
              "status": "unknown",
            }
            setGuestData(guestData);
          }
          else {
            let guestData = {
              "status": "error",
            }
            setGuestData(guestData);
          }

        })
      API.get("WeddingApi", "/rsvp/" + id)
        .then(response => {
          setRsvp(true)

        })
        .catch(error => {
          setRsvp(false)
        })

    }
    else {
      //no id found
      let guestData = {
        "status": "unknown",
      }
      setGuestData(guestData);
      setRsvp(false)
    }
  }, []);

  return (
    <guestDataContext.Provider value={{ guest: guestData, rsvp: rsvp }}>
      <Router>
        <Routes>
          <Route path="/" element={<Pictures/>}>
            <Route path="" element={<PictureOutletCombined />} />
          </Route>
          <Route path="invitation" element={<InvitationPage />}>
          </Route>
          <Route path="cologne" element={<Cologne />} />
          <Route path="italy" element={<Italy />}>
            <Route path="" element={<EventInfo guest={guestData} />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="faq" element={<ItalyFAQ />} />
            <Route path="groceries" element={<Groceries guest={guestData}/>} />
          </Route>
          <Route path="pictures" element={<Pictures />}>
            <Route path="" element={<PictureOutletCombined/>} />
            <Route path="cologne" element={<PictureOutlet />} />
            <Route path="italy" element={<PictureOutlet />} />
          </Route>
        </Routes>
      </Router>
    </guestDataContext.Provider>
  );

}

export default App;
