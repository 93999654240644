import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LinkWithQuery } from "../fragments/LinkWithQuery";
import { useLocation } from "react-router-dom";
function NavBarEvents({ guest, trigger }) {
    const location = useLocation();
    const { hash, pathname, search } = location;
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            var headerHeight = document.querySelector("header").clientHeight;

            if (window.scrollY >= headerHeight) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setIsSticky(false)
    }, [trigger]);

    return (
        <nav className={`horizontal-nav-events ${isSticky ? 'sticky' : ''}`} id="navbarEvents">
            <ul>
                <li><LinkWithQuery to="/invitation">Einladung</LinkWithQuery></li>
                {
                    guest?.invitedEvents?.italy ? (
                        <>
                            <li><LinkWithQuery className={pathname.includes("/italy") || pathname === "/" ? "active" : ""} to="/italy">Toskana</LinkWithQuery></li>
                        </>
                    ) : (null)
                }

            </ul>
        </nav>
    );
}

export default NavBarEvents