import React from 'react';

export function ItalyInfoTextSingle() {
    return (
        <>
            <p><strong>Amici e Famiglia:<br/> F&uuml;nf Tage, ein Versprechen</strong></p>
            <p>Unsere Reise in die Toskana f&uuml;hrt uns zu der lieben Architektin Marina Soldi und ihrem Mann Davide. Die beiden sind vor einigen Jahren aus S&uuml;dtirol in die Chianti-Region in der N&auml;he der Stadt <strong>Arezzo </strong>gezogen und haben dort einen alten Bauernhof gekauft und nach eigenen Pl&auml;nen zu einem typischen Agritursimo-Feriendomizil mit mehren Ferienwohnungen, Pool und einem selbstbetriebenen Restaurant umgebaut.</p>
            <p>Vom <strong>30. Mai bis zum 04. Juni 2024</strong> haben wir das Anwesen f&uuml;r eine gemeinsame Auszeit mit Euch gebucht. Das verl&auml;ngerte Wochenende f&auml;llt auf Fronleichnam, weshalb Du hier einen Urlaubstag sparen kannst.</p>
            <p>Am<strong> 31. Mai 2024 </strong>werden wir gemeinsam auf dem Hof unsere <strong>freie Trauung</strong> feiern. Im Anschluss lassen wir uns von der italienischen K&uuml;che verf&uuml;hren und den Abend bei Musik und guten Getr&auml;nken ausklingen. Wir k&ouml;nnten uns keinen sch&ouml;neren Rahmen daf&uuml;r vorstellen und w&uuml;nschen uns, dass Du Teil dieser besonderen Reise bist.</p>
            <p>F&uuml;r unsere f&uuml;nf Tage in der Toskana haben wir ein tolles <strong>Programm</strong> vorbereitet. Nat&uuml;rlich sind alle Punkte lediglich ein Angebot. Wenn Du es vorziehst stattdessen in der Sonne am Pool zu brutzeln, haben wir daf&uuml;r vollstes Verst&auml;ndnis! Wir m&ouml;chten in erster Linie mit Dir ein paar Tage Auszeit nehmen.</p>
            <p>Falls Du schon ab Montag, den 02. Juni, wieder arbeiten musst, besteht die M&ouml;glichkeit, dass Du die Unterkunft schon am Sonntag abmietest.</p>
            <p>Am liebsten w&uuml;rden wir Dich auf diese gemeinsame Reise mit einem Rundum-Sorglos-Paket einladen, nur leider ist unsere Hochzeitskasse begrenzt. Daher sind wir auf eine <strong>Kostenbeteiligung</strong> in H&ouml;he von <nobr>&euro; 200</nobr> pro Person f&uuml;r die Unterkunft &uuml;ber den gesamten Zeitraum oder <nobr>&euro; 150</nobr> pro Person f&uuml;r die fr&uuml;here Abreise am Sonntag angewiesen.</p>
            <p>Ob ein Ros&eacute; am Pool oder der Kaffee in der Ferienwohnung: auf alle <strong>Getr&auml;nke</strong> vor Ort - und wir hoffen, dass es viele sind, bist Du von uns herzlich eingeladen.</p>
            <p>Falls Du entspannt auf der Terrasse des Restaurants fr&uuml;hst&uuml;cken m&ouml;chtest, bietet Marina ein internationales <strong>Fr&uuml;hst&uuml;ck</strong> mit original italienischen Barista-Spezialit&auml;ten aus dem Siebtr&auml;ger zu einem Preis von <nobr>&euro; 15</nobr> pro Tag an.</p>
            <p>Du fragst Dich, was Du uns schenken kannst?</p>
            <p>Wenn Du diese Reise auf Dich nimmst, ist das f&uuml;r uns das gr&ouml;&szlig;te Geschenk, was Du uns machen kannst! Kein anderes Geschenk kann das &uuml;berbieten.</p>
            <p>Wir freuen uns auf eine unvergessliche Zeit mit Dir!</p>
        </>
    );
}

export function ItalyInfoText() {
    return (
        <>
            <p><strong>Amici e Famiglia:<br/> F&uuml;nf Tage, ein Versprechen</strong></p>
            <p>Unsere Reise in die Toskana f&uuml;hrt uns zu der lieben Architektin Marina Soldi und ihrem Mann Davide. Die beiden sind vor einigen Jahren aus S&uuml;dtirol in die Chianti-Region in der N&auml;he der Stadt <strong>Arezzo </strong>gezogen und haben dort einen alten Bauernhof gekauft und nach eigenen Pl&auml;nen zu einem typischen Agritursimo-Feriendomizil mit mehren Ferienwohnungen, Pool und einem selbstbetriebenen Restaurant umgebaut.</p>
            <p>Vom <strong>30. Mai bis zum 04. Juni 2024</strong> haben wir das Anwesen f&uuml;r eine gemeinsame Auszeit mit Euch gebucht. Das verl&auml;ngerte Wochenende f&auml;llt auf Fronleichnam, weshalb Ihr hier einen Urlaubstag sparen kannst.</p>
            <p>Am<strong> 31. Mai 2024 </strong>werden wir gemeinsam auf dem Hof unsere <strong>freie Trauung</strong> feiern. Im Anschluss lassen wir uns von der italienischen K&uuml;che verf&uuml;hren und den Abend bei Musik und guten Getr&auml;nken ausklingen. Wir k&ouml;nnten uns keinen sch&ouml;neren Rahmen daf&uuml;r vorstellen und w&uuml;nschen uns, dass Ihr Teil dieser besonderen Reise seid.</p>
            <p>F&uuml;r unsere f&uuml;nf Tage in der Toskana haben wir ein tolles <strong>Programm</strong> vorbereitet. Nat&uuml;rlich sind alle Punkte lediglich ein Angebot. Wenn Ihr es vorziehst stattdessen in der Sonne am Pool zu brutzeln, haben wir daf&uuml;r vollstes Verst&auml;ndnis! Wir m&ouml;chten in erster Linie mit Euch ein paar Tage Auszeit nehmen.</p>
            <p>Falls Ihr schon ab Montag, den 02. Juni, wieder arbeiten m&uuml;sst, besteht die M&ouml;glichkeit, dass Ihr die Unterkunft schon am Sonntag abmietet.</p>
            <p>Am liebsten w&uuml;rden wir Euch auf diese gemeinsame Reise mit einem Rundum-Sorglos-Paket einladen, nur leider ist unsere Hochzeitskasse begrenzt. Daher sind wir auf eine <strong>Kostenbeteiligung</strong> in H&ouml;he von <nobr>&euro; 200</nobr> pro Person f&uuml;r die Unterkunft &uuml;ber den gesamten Zeitraum oder <nobr>&euro; 150</nobr> pro Person f&uuml;r die fr&uuml;here Abreise am Sonntag angewiesen.</p>
            <p>Ob ein Ros&eacute; am Pool oder der Kaffee in der Ferienwohnung: auf alle <strong>Getr&auml;nke</strong> vor Ort - und wir hoffen, dass es viele sind, seid Ihr von uns herzlich eingeladen.</p>
            <p>Falls Ihr entspannt auf der Terrasse des Restaurants fr&uuml;hst&uuml;cken m&ouml;chtest, bietet Marina ein internationales <strong>Fr&uuml;hst&uuml;ck</strong> mit original italienischen Barista-Spezialit&auml;ten aus dem Siebtr&auml;ger zu einem Preis von <nobr>&euro; 15</nobr> pro Tag an.</p>
            <p>Ihr fragt Euch, was Ihr uns schenken k&ouml;nnt?</p>
            <p>Wenn Ihr diese Reise auf Euch nehmt, ist das f&uuml;r uns das gr&ouml;&szlig;te Geschenk, was Ihr uns machen k&ouml;nnt! Kein anderes Geschenk kann das &uuml;berbieten.</p>
            <p>Wir freuen uns auf eine unvergessliche Zeit mit Euch!</p>
        </>
    );
}


export function CologneInfoTextSingle() {
    return (
        <>
            <p>Vielen Dank, dass du da warst!</p>
        </>
    );
}

export function CologneInfoText() {
    return (
        <>
            <p>Vielen Dank, dass Ihr da wart!</p>
        </>
    );
}
