import headerpic from '../content/headerpic.jpg';
import { Image, Container } from "@mantine/core"

function Header({ countdownEnd }) {
  return (
    <div className="image-container">
      <Container p={0}>
        <Image src={headerpic} className="image" />

      </Container>
      {/*
        <div className="text-overlay">
          <p>Fiona und Harper heiraten</p>
        </div>
    */}
    </div>

  );
}
export default Header