import React from 'react';

export function GenericInvitationText() {
    return (
        <p>Wir feiern unsere Hochzeit am <strong>03. Mai 2024</strong> und wollen diesen besonderen Tag mit Euch verbringen!</p>
    );
}

export function GenericInvitationTextSingle() {
    return (
        <p>Wir feiern unsere Hochzeit am <strong>03. Mai 2024</strong> und wollen diesen besonderen Tag mit Dir verbringen!</p>
    );
}

export function CeremonyInvitationText() {
    return (
        <p>Unsere <strong>standesamtliche Trauung</strong> findet um <strong>15:15 Uhr</strong> im Turmkeller des historischen Rathauses am K&ouml;lner Altermarkt statt. Wir freuen uns wenn Ihr fr&uuml;hzeitig da seid, damit Ihr unseren wichtigen Moment nicht verpasst.</p>
    );
}
export function CeremonyInvitationTextSingle() {
    return (
        <p>Unsere <strong>standesamtliche Trauung</strong> findet um <strong>15:15 Uhr</strong> im Turmkeller des historischen Rathauses am K&ouml;lner Altermarkt statt. Wir freuen uns wenn Du fr&uuml;hzeitig da bist, damit Du unseren wichtigen Moment nicht verpasst.</p>
    );
}

export function ChampagneShowerText() {
    return (
        <p>Nach der Trauung im historischen Rathaus gegen <strong>15:30 Uhr</strong> m&ouml;chten wir gerne mit Euch in unserem karnevalistischen Stammlokal &bdquo;<strong>Kunst Kasch&auml;mm</strong>&ldquo; ansto&szlig;en.</p>
    );
}
export function ChampagneShowerTextSingle() {
    return (
        <p>Nach der Trauung im historischen Rathaus gegen <strong>15:30 Uhr </strong>m&ouml;chten wir gerne mit Dir in unserem karnevalistischen Stammlokal &bdquo;<strong>Kunst Kasch&auml;mm</strong>&ldquo; ansto&szlig;en.</p>
    );
}
export function PartyInvitationText() {
    return (
        <p>Ab <strong>16:30 Uhr</strong> sind dann die Tore des <strong>Fort X</strong> am Rosengarten f&uuml;r uns ge&ouml;ffnet. Dort m&ouml;chten wir gemeinsam mit Euch feiern und den Tag gem&uuml;tlich ausklingen lassen.</p>
    );
}
export function PartyInvitationTextSingle() {
    return (
        <p>Ab <strong>16:30 Uhr</strong> sind dann die Tore des <strong>Fort X</strong> am Rosengarten f&uuml;r uns ge&ouml;ffnet. Dort m&ouml;chten wir gemeinsam mit Dir feiern und den Tag gem&uuml;tlich ausklingen lassen.</p>
    );
}

export function ItalyInvitationText() {
    return (
        <>
            <p>
                <strong>Amici e Famiglia:<br /> F&uuml;nf Tage, ein Versprechen <br /></strong>
                F&uuml;r unsere<strong> freie Trauung</strong> haben wir uns etwas ganz Besonderes &uuml;berlegt. Eine gro&szlig;e Hochzeitsfeier ist zwar etwas Sch&ouml;nes, ausreichend Zeit, die wir am liebsten mit Euch an diesem besonderen Tag verbringen w&uuml;rden, hätten wir dann aber sicher nicht. Dem Stress des Hochzeitstages m&ouml;chten wir gerne zusammen mit Euch entfliehen und stattdessen im kleinen Kreis unvergessliche und entspannte Tage verbringen - an einem Ort, der f&uuml;r uns alle pure <strong>Urlaubsstimmung</strong> verspr&uuml;ht.</p>
            <p>In diesem kleinen Kreis m&ouml;chten wir in die wundersch&ouml;ne <strong>Toskana</strong> reisen. Bei warmen Temperaturen, k&uuml;hlen Getr&auml;nken und der grandiosen italienischen K&uuml;che m&ouml;chten wir ein paar sch&ouml;ne Tage mit Euch verbringen. Wir freuen uns riesig, wenn Ihr Euch unserer kleinen Reisegruppe Richtung S&uuml;den anschlie&szlig;t.</p>
        </>
    );
}

export function ItalyInvitationTextSingle() {
    return (
        <>
            <p>
                <strong>Amici e Famiglia:<br /> F&uuml;nf Tage, ein Versprechen<br /></strong>
                F&uuml;r unsere <strong>freie Trauung</strong> haben wir uns etwas ganz Besonderes &uuml;berlegt. Eine gro&szlig;e Hochzeitsfeier ist zwar etwas Sch&ouml;nes, ausreichend Zeit, die wir gerne mit Dir an diesem besonderen Tag verbringen w&uuml;rden, hätten wir dann aber sicher nicht. Dem Stress des Hochzeitstages m&ouml;chten wir gerne zusammen mit Dir entfliehen und stattdessen im kleinen Kreis unvergessliche und entspannte Tage verbringen - an einem Ort, der f&uuml;r uns alle pure <strong>Urlaubsstimmung</strong> verspr&uuml;ht.</p>
            <p>In diesem kleinen Kreis m&ouml;chten wir in die wundersch&ouml;ne <strong>Toskana</strong> reisen. Bei warmen Temperaturen, k&uuml;hlen Getr&auml;nken und der grandiosen italienischen K&uuml;che m&ouml;chten wir ein paar sch&ouml;ne Tage mit Euch verbringen. Wir freuen uns riesig, wenn Du Dich unserer kleinen Reisegruppe Richtung S&uuml;den anschlie&szlig;t.</p>
        </>

    );
}

export function PresentTextCologneOnly() {
    return (
        <p>
            Für uns ist das größte Geschenk ein unvergesslicher Tag mit Euch. Um diesen besonderen Anlass noch unvergesslicher zu gestalten, möchten wir Euch bitten, von materiellen Geschenken abzusehen. Stattdessen würden wir uns über eine kleine finanzielle Unterstützung für unsere Flitterwochen freuen.
        </p>
    );
}
export function PresentTextCologneOnlySingle() {
    return (
        <p>
            Für uns ist das größte Geschenk ein unvergesslicher Tag mit Euch. Um diesen besonderen Anlass noch unvergesslicher zu gestalten, möchten wir Dich bitten, von materiellen Geschenken abzusehen. Stattdessen würden wir uns über eine kleine finanzielle Unterstützung für unsere Flitterwochen freuen. Ein Sparschwein wird vor Ort sein, welches uns auf unserer ersten Reise in der Ehe begleiten wird.
        </p>
    );
}
export function FeedbackText() {
    return (
        <p>
            Auf der nächsten Seite findet Ihr ein Formular, über das Ihr uns eine Rückmeldung geben könnt. Bis dahin planen wir natürlich fest mit Eurer Zusage.        </p>
    );
}
export function FeedbackTextSingle() {
    return (
        <p>
            Auf der nächsten Seite findest Du ein Formular, über das Du uns eine Rückmeldung geben kannst. Bis dahin planen wir natürlich fest mit Deiner Zusage.        </p>
    );
}
