import React, { useContext, useEffect, useState } from 'react';
import Invitation from '../components/Invitation';
import Locations from '../components/Locations';
import Schedule from '../components/Schedule';
import Header from "../components/Header"
import RSVPForm from '../components/RsvpForm';
import NavBar from '../components/NavBar';
import NavBarEvents from '../components/NavBarEvents';
import NavBarPictures from '../components/NavBarPictures';
import LoadingScreen from '../fragments/LoadingScreen';
import { guestDataContext } from '../contexts/GuestDataContext';
import Footer from '../components/Footer';
import locationMarina from '../content/location_Marina.jpg'
import { FaHome } from "react-icons/fa";
import { BiDrink } from "react-icons/bi";
import { GiLinkedRings } from "react-icons/gi";
import { LuPartyPopper, LuSun } from "react-icons/lu";
import { PiCoffee } from "react-icons/pi";
import { MdPool } from "react-icons/md";
import { PiWineFill } from "react-icons/pi";
import { FaBus } from "react-icons/fa";
import { PiAirplaneTakeoffBold } from "react-icons/pi";
import EventInfo from '../components/EventInfo';
import FAQ from '../components/FAQ';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { NavigateWithQuery } from '../fragments/NavigateWithQuery';
import PictureOutlet from '../components/pictures/PictureOutlet';
import PictureOutletCombined from '../components/pictures/PictureOutletCombined';


function Pictures() {
    const [resetNavPosition, setResetNavPosition] = useState(false);

    const location = useLocation();
    const { hash, pathname, search } = location;
    useEffect(() => {
        window.scrollTo(0, 0);
        setResetNavPosition(true)
    }, []);

    const { guest, rsvp } = useContext(guestDataContext);

    if (guest && guest?.status === "success") {
        return (
            <div>
                <header style={{ justifyContent: "center" }}>
                    <Header countdownEnd={'June 1, 2024 00:00:00'} />
                </header>
                <main>
                    {/*<NavBarPictures trigger={resetNavPosition} />*/}
                    {pathname === "/" ?
                        <PictureOutletCombined />
                        : <Outlet />
                    }
                    <Footer />
                </main>
            </div>
        )
    }

    else {
        return (
            <LoadingScreen guestStatus={guest?.status} rsvp={rsvp} />
        )
    }
}

export default Pictures;
