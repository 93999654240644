import { LuPartyPopper } from "react-icons/lu";
function ScheduleActivity({ date, time, title, subtitle, description, icon }) {
    return (
        !date ? (
            <li className="activity">
                <div className="icon-wrapper">
                    <div className="icon">
                        {/* Include the appropriate icon or image */}
                        {icon}
                    </div>
                </div>
                <div className="activity-content">
                    <h3 className="activity-time">{time ? `${time}` : ''}</h3>
                    <h3 className="activity-headline">{title}</h3>
                    <h3 className="activity-subtitle">{subtitle}</h3>
                    <p className="activity-description">{description}</p>
                </div>
            </li>
        )
        :(
            <li className="activity">
                <div className="activity-content">
                    <h3 className="activity-date">{date}</h3>
                </div>
            </li>
        )
    );
}
export default ScheduleActivity