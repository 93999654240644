import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import Invitation from '../components/Invitation';
import Locations from '../components/Locations';
import Schedule from '../components/Schedule';
import Header from "../components/Header"
import LoadingScreen from '../fragments/LoadingScreen';
import NavBar from '../components/NavBar';
import NavBarEvents from '../components/NavBarEvents';
import RSVPForm from '../components/RsvpForm';
import { guestDataContext } from '../contexts/GuestDataContext';
import Footer from '../components/Footer';
import FAQ from '../components/FAQ';
import { LuPartyPopper } from "react-icons/lu";
import Organizers from '../components/Organizers';
import { FaChampagneGlasses } from "react-icons/fa6";
import { LiaRingSolid } from "react-icons/lia";
import locationFortX from '../content/location_FortX.jpg'
import locationKunstKaschaemm from '../content/location_Kunstkaschaemm.jpg'
import locationRathaus from '../content/location_Rathaus.jpg'
import EventInfo from '../components/EventInfo';


var locations = [
    {
        "name": "Standesamtliche Trauung",
        "location": "Historisches Rathaus Köln",
        "address": "Altermarkt, 50667 Köln",
        "mapLink": "https://www.google.com/maps/place/Historisches+Rathaus+der+Stadt+Köln/@50.938076,6.9570107,17z/data=!3m2!4b1!5s0x47bf25b1cd626a3d:0xb8013dc5ccf8fbb9!4m6!3m5!1s0x47bf25b1b248f609:0x3d2a5f844520af2!8m2!3d50.938076!4d6.9595856!16s%2Fm%2F0gj9jdw?hl=de-DE&entry=ttu",
        "image": locationRathaus
    },
    {
        "name": "Amore Frizzante",
        "location": "Kunst Kaschämm",
        "address": "Marsplatz, 50667 Köln",
        "mapLink": "https://www.google.com/maps/place/Kunstkaschaemm/@50.9377197,6.9547733,17z/data=!3m1!4b1!4m6!3m5!1s0x47bf25b1af931ab9:0x975ea3069c45ca0e!8m2!3d50.9377164!4d6.9596442!16s%2Fg%2F12hq1bqbs?entry=ttu",
        "image": locationKunstKaschaemm

    },
    {
        "name": "Pizza-Party für die Liebe",
        "location": "Fort X",
        "address": "Neusser Wall 33, 50670 Köln",
        "mapLink": "https://www.google.com/maps/place/Fort+X/@50.9579059,6.9597113,17z/data=!3m1!4b1!4m6!3m5!1s0x47bf2599ff8ea683:0x77004f1bb2fbfe10!8m2!3d50.9579025!4d6.9622862!16s%2Fg%2F11bbxwdxf8?entry=ttu",
        "image": locationFortX

    }
]

var activitiesAll = [
    {
        "date": "Freitag, 3. Mai",
    },
    {
        "time": "15:15",
        "title": "Standesamtliche Trauung",
        "description": "",
        "icon": <LiaRingSolid />
    },
    {
        "time": "15:30",
        "title": "Amore Frizzante",
        "description": "",
        "icon": <FaChampagneGlasses />

    },
    {
        "time": "16:30",
        "title": "Pizza-Party für die Liebe",
        "description": "",
        "icon": <LuPartyPopper />
    },

]
var activitiesPartyOnly = [
    {
        "date": "Freitag, 3. Mai",
    },
    {
        "time": "15:30",
        "title": "Amore Frizzante",
        "description": "",
        "icon": <FaChampagneGlasses />

    },
    {
        "time": "16:30",
        "title": "Pizza-Party für die Liebe",
        "description": "",
        "icon": <LuPartyPopper />
    },
]

var faq = [
    {
        "title": "Ich komme mit dem Auto. Wo kann ich parken?",
        "description":
            <>
                In der Nähe des historischen Rathauses und der Kunst Kaschämm empfehlen wir Euch 	das Parkhaus „Heumarkt“, Markmannsgasse 3, 50667 Köln.
                <br /><br />
                Direkt am Fort X gibt es leider keine Parkplätze. Ihr findet Parkplätze am Straßenrand im Agnesviertel. Der Parkplatz am Kölner Weindepot (Amsterdamer Straße 1, 50668 Köln) ist durchgehend geöffnet und bietet auch außerhalb der Öffnungszeiten 70 Parkplätze ca. drei Minuten fußläufig vom Fort X entfernt.
            </>
    },
    {
        "title": "Ich reise mit dem ÖPNV an. An welcher Haltestelle muss ich aussteigen?",
        "description":
            <>
                Das historische Rathaus und die Kunst Kaschämm erreicht Ihr über die Haltestelle „Rathaus“. Vom Kölner Hauptbahnhof ist es allerdings auch zu Fuß nur fünf Minuten entfernt.
                <br /><br />
                Das Fort X erreicht Ihr über die Haltestellen „Lohsestraße“ oder „Reichensperger Platz“.
            </>
    },
    {
        "title": "Gibt es einen Dresscode?",
        "description":
            <>
                Einen Dresscode gibt es nicht. Uns ist wichtig, dass sich alle bei uns wohlfühlen. Dominik wird einen Anzug tragen, aber der heiratet auch an dem Tag ;)
                <br /><br />
                Im Idealfall findet die Feier draußen statt. Nehmt Euch also am besten eine Jacke mit.
            </>
    },
    {
        "title": "Ich bin allergisch gegen Milch vom zweiköpfigen Einhorn. Bekomme ich trotzdem was zu Essen?",
        "description":
            <>
                Vegane und vegetarische Alternativen stehen für Euch bereit. Sollten wir sonst etwas beachten, lasst es uns gerne wissen.
            </>
    }
]




function Cologne() {
    const [resetNavPosition, setResetNavPosition] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setResetNavPosition(true)
    }, []);


    const { guest, rsvp } = useContext(guestDataContext);
    return (
        guest && guest.status === "success" && rsvp !== "init" ? (
            <div id='top'>
                <header>
                    <Header countdownEnd={'May 4, 2024 00:00:00'} />
                </header>
                <main>
                    <EventInfo guest={guest} />
                    <Footer />
                </main>
            </div>
        )
            : (
                <LoadingScreen guestStatus={guest?.status} rsvp={rsvp} />
            )
    );
}

export default Cologne;
