import footerpic from '../content/footerpic.jpg'
import { Image, Container } from "@mantine/core"

function Footer({ countdownEnd }) {
    return (
        <>
            <footer className="footer">
                <div className="image-container">
                    <Container p={0}>
                        <Image src={footerpic} className="image" />
                    </Container>
                    {/*
        <div className="text-overlay">
          <p>Fiona und Harper heiraten</p>
        </div>
    */}
                </div>
            </footer>
        </>
    );
}
export default Footer