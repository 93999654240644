import { FaWhatsapp, FaPhone } from "react-icons/fa6";
import { SiSignal } from "react-icons/si";
import { CiMail } from "react-icons/ci";

function OrganizerItem({ name, role, description, phone, mail, picture, icons }) {
    const phoneDecode = atob(phone) || ""
    const normalizedPhone = (phoneDecode.match(/\d+/g)).join("");
    const mailDecode = atob(mail)
    return (
        <li className="organizer">
            <div className="organizer-image hexagon">
                <img src={picture} alt="" className="organizer-image circle" />
            </div>
            <div className="organizer-details">
                <p className="name">{name}</p>
                <p className="func">{role}</p>
                <div className="contact">
                    {phoneDecode}
                    <br />
                    <a href={`mailto:${mailDecode}`}>{mailDecode}</a>
                    <div className="contactIcons">
                        {
                            icons?.includes("phone")? (
                                <a href={`tel:${normalizedPhone}`}> <FaPhone size={"18px"} /></a>
                            ):(null)
                        }
                        {
                            icons?.includes("mail")? (
                                <a href={`mailto:${mailDecode}`}><CiMail size={"18px"} /></a>
                            ):(null)
                        }
                        {
                            icons?.includes("whatsapp")? (
                                <a href={`https://wa.me/${normalizedPhone}`} target="about_blank"><FaWhatsapp size={"18px"} /> </a>
                            ):(null)
                        }
                        {
                            icons?.includes("signal")? (
                                <a href={`https://signal.me/#p/+${normalizedPhone}`} target="about_blank"><SiSignal size={"18px"} /></a>
                            ):(null)
                        }

                    </div>
                </div>
                <p className="description">{description}</p>

                {/*
                <p className="url">
                    <strong>Adresse:</strong>
                    <a href={mapLink} target="_blank" rel="noreferrer">{address}</a>
                </p>
                */}
            </div>
        </li>
    );
}
export default OrganizerItem