import React from 'react';

import { useLocation } from 'react-router';
import { ItalyInfoTextSingle, ItalyInfoText, CologneInfoTextSingle, CologneInfoText } from '../fragments/EventInfoText';
function EventInfo({ guest }) {
    const location = useLocation();
    const { hash, pathname, search } = location;

    return (
        <section id="invitation">
            <div className="invitation-content">
                {guest ? (
                    <div>
                        {
                            pathname === "/cologne" ? (
                                <>
                                {
                                    guest.allowedTotalGuests.toString() === "1" ? (
                                        <CologneInfoTextSingle />
                                    )
                                        : (
                                            <CologneInfoText />
                                        )
                                }
                            </>
                            ) : (
                                pathname === '/italy' || pathname === "/" ? (
                                    <>
                                        {
                                            guest.allowedTotalGuests.toString() === "1" ? (
                                                <ItalyInfoTextSingle />
                                            )
                                                : (
                                                    <ItalyInfoText />
                                                )
                                        }
                                    </>
                                ) : (null)
                            )}
                    </div>
                ) : (null)
                }
            </div>
        </section>
    );

}

export default EventInfo;
