import React from 'react';
import Heading from '../fragments/Heading';
import OrganizerItem from '../fragments/OrganizerItem';

import portraitMM from '../content/organizer_mm.jpg'
import portraitVera from '../content/organizer_vera.png'
import portraitDana from '../content/organizer_dana.png'


var organizers = [
    {
        "name": "Vera",
        "role": "Trauzeugin",
        "description": "Vera hat ein unglaublich großes Sprachentalent. Nur kann sie nicht bloß Wörter übersetzen. Seit meiner Geburt übersetzt sie mir auch die ganze Welt. Bei scheinbar unlösbaren Herausforderungen, lässt sie oft meine Sorgen in Luft auflösen. Durch sie habe ich die beste Schwester und jetzt auch Trauzeugin und wäre ohne sie einfach nicht komplett.",
        "phone": "KzQ5IDE3MiA1NDA1NTc0",
        "mail": "bWFpbEB2ZXJhcmljaGFydHouZGU=",
        "picture": portraitVera,
        "icons": ["phone","mail", "whatsapp" ]

    },
    {
        "name": "Marco",
        "role": "Trauzeuge",
        "description": "Marco und ich haben in der Schule zusammen den Unterricht geschwänzt, über viele Jahre unzählige Konzerte und Festivals besucht, neue Länder zusammen entdeckt und sogar beruflich kann ich zu jeder Zeit auf ihn zählen.",
        "phone": "KzQ5IDE3NyAzNDcwODI3",
        "mail": "aG9jaHplaXRAbWFyY29taWNoYWxza2kuZGU=",
        "picture": portraitMM,
        "icons": ["phone", "mail", "whatsapp", "signal"]
    },
    {
        "name": "Dana",
        "role": "",
        "description": "Von der kleinen und manchmal nervigen Schwester ist heute nicht mehr viel übrig. Dana ist unermüdliche Problemlöserin, unerschöpfliche Quelle der guten Laune und unbrechbare Stütze in allen Lebenslagen.",
        "phone": "KzQ5IDE3NiA1NjgyNjk4NA==",
        "mail": "ZGFuYS5iZW5zaWVrQG91dGxvb2suZGU=",
        "picture": portraitDana,
        "icons": ["phone", "whatsapp", "mail" ]

    }
]

function Organizers({ locations }) {
    return (
        <>
            <section id="location" className="section organizers">
                <Heading heading={"Kontakt"} subheading={"Falls ihr Fragen habt, zögert bitte nicht, Euch an die besten Trauzeug:innen zu wenden. Sie stehen Euch gerne bei allen Fragen zu Verfügung."} />
                <div className="container">
                    <ul className="organizers-list">
                        {organizers ? (
                            organizers.map((element, index) => (
                                <OrganizerItem
                                    name={element.name}
                                    role={element.role}
                                    description={element.description}
                                    phone={element.phone}
                                    mail={element.mail}
                                    picture={element.picture}
                                    icons={element.icons}
                                    key={index}
                                />
                            ))
                        ) : null}
                    </ul>
                </div>
            </section>
        </>
    );
}



export default Organizers;
