import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { GenericInvitationText, GenericInvitationTextSingle, ChampagneShowerText, ChampagneShowerTextSingle, PartyInvitationText, PartyInvitationTextSingle, ItalyInvitationText, ItalyInvitationTextSingle, CeremonyInvitationTextSingle, CeremonyInvitationText, PresentTextCologneOnly, PresentTextCologneOnlySingle, FeedbackText, FeedbackTextSingle } from '../fragments/InvitationText';
import { LinkWithQuery } from '../fragments/LinkWithQuery';
import { useLocation } from 'react-router';
function Invitation({ guest }) {
    const location = useLocation();
    const { hash, pathname, search } = location;
    console.log(pathname)
    const salutationString = guest.salutation.split(",")


    return (
        <section id="invitation">
            <div className="invitation-content">
                {guest ? (
                    <div>
                        <h1>{salutationString.map((salutation, index) => (
                            <div key={index}>
                                {salutation + ","}
                                <br />
                            </div>

                        ))}</h1>
                        {
                            guest.allowedTotalGuests.toString() === "1" ? (
                                <GenericInvitationTextSingle />
                            )
                                : (
                                    <GenericInvitationText />
                                )
                        }
                        {
                            guest.invitedEvents.ceremony ? (

                                guest.allowedTotalGuests.toString() === "1" ? (
                                    <CeremonyInvitationTextSingle />
                                ) : (
                                    <CeremonyInvitationText />
                                )



                            ) : (null)
                        }
                        {
                            guest.allowedTotalGuests.toString() === "1" ? (
                                <ChampagneShowerTextSingle />
                            ) : (
                                <ChampagneShowerText />
                            )
                        }
                        {
                            guest.invitedEvents.party ? (

                                guest.allowedTotalGuests.toString() === "1" ? (
                                    <PartyInvitationTextSingle />
                                )
                                    : (
                                        <PartyInvitationText />
                                    )
                            ) : (null)
                        }
                        {
                            guest.invitedEvents.italy ? (

                                guest.allowedTotalGuests.toString() === "1" ? (
                                    <ItalyInvitationTextSingle />
                                )
                                    : (
                                        <ItalyInvitationText />
                                    )
                            ) : (
                                /* If guest is not invited to Italy, render Cologne present Text */
                                guest.allowedTotalGuests.toString() === "1" ? (
                                    <PresentTextCologneOnlySingle />
                                )
                                    : (
                                        <PresentTextCologneOnly />
                                    )
                            )
                        }
                        {/*
                            guest.allowedTotalGuests.toString() === "1" ? (
                                <FeedbackTextSingle />
                            )
                                : (
                                    <FeedbackText />
                                )
                                */
                        }
                        {
                            guest.invitedEvents.italy && pathname !== "/italy" ? (
                                <>
                                    {/* 
                                    <p>
                                        <Link to="/cologne">
                                            <button className='btn btn-block submit-button'>Hier gehts zu den Infos für Köln</button>
                                        </Link>
                                    </p>
                                    */}
                                    <p>
                                        <LinkWithQuery to="/italy">
                                            <button className='btn btn-block submit-button'>Hier gehts zu den Infos für die Toskana</button>
                                        </LinkWithQuery>
                                    </p>
                                </>
                            ) : (
                                null
                            )
                        }
                    </div>
                ) : (
                    null
                )}
            </div>
        </section>
    );

}

export default Invitation;
