import { Indicator } from "@mantine/core"
import LazyLoad from 'react-lazyload';

import { Loader } from '@mantine/core';

export const LazyLoadingImage = ({ children, src, ...props }) => {
  return (
    <LazyLoad height={"100%"} overflow={true} placeholder={
      <div style={{ minHeight: "180px", minWidth: "100px" }}>
        <Loader color='#9fbea0' />
      </div>
    }
      offset={100}
    >
      <Indicator color='red' inline label={"neu"} size={"20"} disabled={!props.item.new} offset={10}>
        <img alt='x' loading='lazy' {...props.imageProps} />
      </Indicator>
    </LazyLoad>
  );
};