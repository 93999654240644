import React from 'react';

function Heading({ heading, subheading }) {
    return (
        <section className="section header-section">
            <div className="container">
                <div className="headline-font-wrapper">
                    <h2>{heading}</h2>
                </div>
                {/*<p className="subheading">
                    {subheading}
                </p>
    */}
            </div>
        </section>
    );
}

export default Heading;
