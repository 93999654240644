import { React, useContext } from 'react';
import ScheduleActivity from '../../fragments/ScheduleActivity';
import Heading from '../../fragments/Heading';

import { Tabs, rem } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import { Container, Card } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { Image } from "@mantine/core"
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Button } from '@mantine/core';
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { Group, Text } from '@mantine/core';
import { IconUpload, IconPhoto } from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';
import { API } from 'aws-amplify';
import { guestDataContext } from '../../contexts/GuestDataContext';
import { Hearts } from "react-loader-spinner"


import { useEffect, useState } from 'react';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


function PictureOutlet(trigger) {
    const { guest, rsvp } = useContext(guestDataContext);

    const [featuredPictures, setFeaturedPictures] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    const { hash, pathname, search } = location;

    var eventLocation = ""
    if (pathname === "/" || pathname === "/pictures/cologne") {
        eventLocation = "cologne"
    }
    else if (pathname === "/pictures/italy") {
        eventLocation = "italy"
    }


    const [isSticky, setIsSticky] = useState(false);
    const [top, setTop] = useState(0);
    const [spacerHeight, setSpacerHeight] = useState(0);

    //372
    useEffect(() => {

        const handleScroll = () => {
            setSpacerHeight(0)
            let spacer = 0
            if (document.querySelector(".horizontal-nav")) {
                spacer = spacer + document.querySelector(".horizontal-nav").clientHeight
            }
            var headerHeight = document.querySelector("header").clientHeight;
            if (window.scrollY >= (headerHeight)) {
                setIsSticky(true);
                if (document.querySelector(".horizontal-nav-events")) {
                    //setTop(document.querySelector(".horizontal-nav-events").clientHeight - 1)
                    spacer = spacer + document.querySelector(".horizontal-nav-events").clientHeight
                    setTop(spacer - 1)
                }
                setSpacerHeight(spacer)
            } else {
                setIsSticky(false);
                setTop(0)
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setSpacerHeight(0)
        setTop(0)
        setIsSticky(false)
    }, [trigger]);
    useEffect(() => {
        setIsLoading(true)
        API.get("WeddingApi", `/pictures/${eventLocation}/featured?id=${guest.id}`)
            .then(response => {
                console.log(response)
                setFeaturedPictures(response)
                setIsLoading(false)
            })
            .catch(error => {
                console.log(error)

            })



    }, [eventLocation]);

    function scrollToTop() {
        if (isSticky) {
            let calc = top + document.querySelector(".tabheader.sticky").clientHeight + top + 5
            window.scrollTo(0, calc)
        }
    }
    function downloadZip() {
        API.get("WeddingApi", `/pictures/${eventLocation}/photographer?id=${guest.id}`)
            .then(response => {
                console.log(response)
                // Convert base64 response to a blob
                const binary = atob(response);
                const array = new Uint8Array(binary.length);
                for (let i = 0; i < binary.length; i++) {
                    array[i] = binary.charCodeAt(i);
                }
                const blob = new Blob([array], { type: 'application/zip' });

                // Create a link element and programmatically click it to download the zip file
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'your-zip-file-name.zip';
                link.click();
            })
            .catch(error => {
                console.log(error)

            })
    }
    function downloadPhotographer() {
        let locationName = ""
        if (eventLocation === "cologne") {
            locationName = "Koeln"
        }
        else if(eventLocation === "italy"){
            locationName = "Italien"
        }
        API.get("WeddingApi", `/pictures/${eventLocation}/photographer?id=${guest.id}`)
            .then(url => {
                axios.get(url, {
                    responseType: 'blob', // Important
                }).then(response => {
                    const content = new Blob([response.data], { type: 'application/zip' });
                    const fileName = `Hochzeit-FionaDominik-Fotograf-${locationName}.zip`; // Choose your own file name
                    saveAs(content, fileName);
                }).catch(error => {
                    console.error('Error while downloading the file', error);
                });
            })
            .catch(error => {
                console.log(error)
            })
    }
    async function uploadFiles(files) {
        setIsUploading(true)
        for (const file of files) {
            console.log(file)
            try {
                const response = await API.get('WeddingApi', '/pictures/uploadurl', {
                    queryStringParameters: {
                        fileName: file.name,
                        fileType: file.type,
                        id: guest.id
                    }
                });
                console.log(response)
                const result = await fetch(response.uploadURL, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        'Content-Type': file.type,
                    },
                });

                if (result.ok) {
                    console.log(`File ${file.name} uploaded successfully!`);
                } else {
                    console.error(`Upload failed for file ${file.name}.`);
                }
            } catch (error) {
                console.error('Error uploading file: ', error);
            }
        }
        setIsUploading(false)

    }
    const autoplay = useRef(Autoplay({ delay: 2000 }));

    return (

        <>
            <Container align={"center"} mt={"20px"}>
                {
                    !isLoading ?
                        <Carousel withIndicators loop
                            style={{ flex: 1 }}
                            plugins={[autoplay.current]}>
                            {
                                featuredPictures.map(url => (
                                    <Carousel.Slide>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%'
                                        }}>
                                            <Image src={url} />
                                        </div>
                                    </Carousel.Slide>
                                ))
                            }
                        </Carousel>
                        : (
                            <div className='loading-box insite'>
                                <Hearts height={180} width={380} color='#9fbea0' />
                            </div>
                        )
                }
                <Card withBorder shadow="sm" radius="md" mt={"md"} mb={"md"}>
                    <Card.Section withBorder inheritPadding py="xs">
                        <Group justify="center">
                            <Text fw={500}>Fotos der Fotografen</Text>
                        </Group>
                        <Text c="dimmed" size="sm">
                            Lade hier die professionellen Fotos heruter
                        </Text>
                    </Card.Section>
                    <Card.Section withBorder p={"sm"}>
                        <Button color='#9fbea0' onClick={() => downloadPhotographer()}>{eventLocation === "cologne" ? "Fotos aus Köln herunterladen" : (eventLocation === "italy" ? "Fotos aus der Toskana herunterladen" : null)}</Button>
                    </Card.Section>
                </Card>
                <Card withBorder shadow="sm" radius="md" mt={"md"} mb={"md"}>
                    <Card.Section withBorder inheritPadding py="xs">
                        <Group justify="center">
                            <Text fw={500}>Fotos der Gäste</Text>
                        </Group>
                        <Text c="dimmed" size="sm">
                            Wir sammeln gerade eure Fotos und werden sie euch bald zur Verfügung stellen
                        </Text>
                    </Card.Section>
                    <Card.Section withBorder p={"sm"}>
                        <Button color='#9fbea0' disabled onClick={() => downloadPhotographer()}>{eventLocation === "cologne" ? "Fotos aus Köln herunterladen" : (eventLocation === "italy" ? "Fotos aus der Toskana herunterladen" : null)}</Button>
                    </Card.Section>
                </Card>
                <Dropzone
                    onDrop={(files) => uploadFiles(files)}
                    onReject={(files) => console.log('rejected files', files)}
                    maxSize={5 * 1024 ** 2}
                    accept={IMAGE_MIME_TYPE}
                    loading={isUploading}
                >
                    <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none' }}>
                        <Dropzone.Accept>
                            <IconUpload
                                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                                stroke={1.5}
                            />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                            <IconX
                                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                                stroke={1.5}
                            />
                            <div>
                                <Text size="xl" inline>
                                    Das sieht leider nicht gut aus.
                                </Text>
                                <Text size="sm" c="dimmed" inline mt={7}>
                                    Achte darauf, dass die Datei ein Foto ist und unter 10MB groß ist.
                                </Text>
                            </div>
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                            <IconPhoto
                                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }}
                                stroke={1.5}
                            />
                            <div>
                                <Text size="xl" inline>
                                    Lade hier deine Fotos hoch.
                                </Text>
                                <Text size="sm" c="dimmed" inline mt={7}>
                                    Wir schauen nochmal drüber und stellen sie dann ggf. den anderen bereit.
                                </Text>
                            </div>
                        </Dropzone.Idle>
                    </Group>
                </Dropzone>
            </Container>
        </>
    );
}



export default PictureOutlet;
