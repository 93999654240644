import React from 'react';
import ScheduleActivity from '../../fragments/ScheduleActivity';
import Heading from '../../fragments/Heading';

import { Tabs, rem } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import { Container } from '@mantine/core';
import { FaHome } from "react-icons/fa";
import { BiDrink } from "react-icons/bi";
import { GiLinkedRings } from "react-icons/gi";
import { LuPartyPopper, LuSun } from "react-icons/lu";
import { PiCoffee } from "react-icons/pi";
import { MdPool } from "react-icons/md";
import { PiWineFill } from "react-icons/pi";
import { FaBus } from "react-icons/fa";
import { PiAirplaneTakeoffBold } from "react-icons/pi";
import { TbToolsKitchen3 } from "react-icons/tb";
import { BsPersonWalking } from "react-icons/bs";
import { MdOutlineTapas } from "react-icons/md";
import { IoRestaurantOutline } from "react-icons/io5";
import { LuChefHat } from "react-icons/lu";
import { PiSunHorizonBold } from "react-icons/pi";
import { MdOutlineTour } from "react-icons/md";

import { useEffect, useState } from 'react';


const iconStyle = { width: rem(12), height: rem(12) };
const activityArray = [
    {
        "day": "Thu",
        "date": "Donnerstag, 30. Mai"
    },
    {
        "day": "Thu",
        "time": "14:00",
        "title": "Pasta-Lunch aus der Cucina Brökers",
        "description": "Alle, die schon früh anreisen, laden wir auf unsere Terasse ein und versorgen Euch mit einer klassischen italienischen Pasta.",
        "icon": <TbToolsKitchen3 />
    },
    {
        "day": "Thu",
        "time": "19:00",
        "title": "Spaziergang zum Arno",
        "description": "Schon Leonardo da Vinci hat Zeit am Arno verbracht. Bei einem kleinen Spaziergang besuchen wir eine Brücke, die es sogar  in den Hintergrund der Mona Lisa geschafft hat.",
        "icon": <BsPersonWalking />
    },
    {
        "day": "Thu",
        "time": "20:00",
        "title": "Drinks und Pizza",
        "description": "",
        "icon": <BiDrink />
    },
    {
        "day": "Fri",
        "date": "Freitag, 31. Mai"
    },
    {
        "day": "Fri",
        "time": "08:30 - 10:30",
        "title": "Frühstück",
        "description": "",
        "icon": <PiCoffee />
    },
    {
        "day": "Fri",
        "time": "11:00 - 14:00",
        "title": "Tour nach Arezzo",
        "subtitle": "(inkl. Snackoption)",
        "description": "Während die Location für die Feier vorbereitet wird, nutzen wir die Zeit um gemeinsam die kleinen Gassen und schiefen Plätzen von Arezzo zu erkunden. ",
        "icon": <FaBus />
    },
    {
        "day": "Fri",
        "time": "17:00",
        "title": "Freie Trauung",
        "description": "",
        "icon": <GiLinkedRings />
    },
    {
        "day": "Fri",
        "time": "17:45",
        "title": "Aperitivo & Antipasti",
        "description": "Stoßt mit uns auf die Liebe an! Am Pool gibt es ausreichend Kaltgetränke, den ersten Gang im Stehen und den perfekten Hintergrund für Fotos.",
        "icon": <MdOutlineTapas />
    },
    {
        "day": "Fri",
        "time": "19:00",
        "title": "Toskanisches Hochzeitsmenü",
        "description": "Euch erwarten unsere Lieblingsgerichte aus der toskanischen Küche in vier Gängen. Niemand geht hungrig ins Bett - versprochen!",
        "icon": <IoRestaurantOutline />
    },
    {
        "day": "Fri",
        "time": "21:30",
        "title": "Musik und Tanz",
        "description": "",
        "icon": <LuPartyPopper />
    },
    {
        "day": "Sat",
        "date": "Samstag, 1. Juni"
    },
    {
        "day": "Sat",
        "time": "08:30 - 10:30",
        "title": "Frühstück",
        "description": "",
        "icon": <PiCoffee />
    },
    {
        "day": "Sat",
        "time": "10:00",
        "title": "Pool open",
        "description": "",
        "icon": <LuSun />
    },
    {
        "day": "Sat",
        "time": "16:00 - 21:00",
        "title": "Ausflug nach Siena",
        "subtitle": "(inkl. Dinner-Option)",
        "description": "Wer die Nacht bis zum Nachmittag verarbeitet hat, kann mit uns die malerische Stadt Siena erkunden. Den Tisch für das gemeinsame Abendessen reservieren wir vorab.",
        "icon": <FaBus />
    },
    {
        "day": "Sun",
        "date": "Sonntag, 2. Juni"
    },
    {
        "day": "Sun",
        "time": "08:30 - 10:30",
        "title": "Frühstück",
        "description": "",
        "icon": <PiCoffee />
    },
    {
        "day": "Sun",
        "time": "10:00",
        "title": "Pool open",
        "description": "",
        "icon": <LuSun />
    },
    {
        "day": "Sun",
        "time": "12:00",
        "title": "Pasta Fresca Kurs",
        "description": "Marina und Davide sind ganz landestypisch Profis in der Küche. Natürlich wissen sie, wie man frische Pasta richtig herstellt. An diesem Mittag teilen sie ihr Können mit uns und lassen uns sogar selbst Hand anlegen.",
        "icon": <LuChefHat />
    },
    {
        "day": "Sun",
        "time": "12:00",
        "title": "Pasta Fresca und Vino zu Mittag",
        "description": "Die frische Pasta muss selbstverständlich im Anschluss verköstigt werden.",
        "icon": <TbToolsKitchen3 />
    },
    {
        "day": "Sun",
        "time": "16:00",
        "title": "Chianti-Region entdecken",
        "description": "Wir machen uns auf zu einer Weinprobe bei einem benachbarten Winzer. Dort bekommen wir eine Führung durch den Keller und ein paar gute Tropfen ins Glas. Passend zu den Weinen werden kleine Snacks vom Hof serviert.",
        "icon": <PiWineFill />
    },
    {
        "day": "Sun",
        "time": "20:00",
        "title": "Sundown-Drinks am Pool",
        "description": "",
        "icon": <PiSunHorizonBold />
    },
    {
        "day": "Mon",
        "date": "Montag, 3. Juni"
    },
    {
        "day": "Mon",
        "time": "08:30 - 10:30",
        "title": "Frühstück",
        "description": "",
        "icon": <PiCoffee />
    },
    {
        "day": "Mon",
        "time": "10:00",
        "title": "Pool open",
        "description": "",
        "icon": <LuSun />
    },
    {
        "day": "Mon",
        "time": "12:00 - 23:00",
        "title": "Tagesausflug nach Florenz",
        "description": "",
        "icon": <FaBus />
    },
    {
        "day": "Mon",
        "time": "13:30 - 15:00",
        "title": "Stadtführung durch Florenz",
        "description": "Zu Fuß erkunden wir gemeinsam die toskanische Hauptstadt mit einem deutschsprachigen Guide.",
        "icon": <MdOutlineTour />
    },
    {
        "day": "Mon",
        "time": "18:00",
        "title": "Abendessen",
        "description": "",
        "icon": <TbToolsKitchen3 />
    },
    {
        "day": "Mon",
        "time": "20:00",
        "title": "Abendspaziergang zum Piazza Michelangelo",
        "description": "Über die malerische Ponte Vecchio führt uns ein Weg bergauf zum Aussichtspunkt Piazza Michelangelo. Auch wenn es hier gerade in den Abendstunden etwas voller wird, ist die Aussicht die beste Belohnung für den kleinen Aufstieg.",
        "icon": <BsPersonWalking />
    },
    {
        "day": "Mon",
        "time": "20:45",
        "title": "Blick in den Sonnenuntergang mit einem Glas in der Hand",
        "description": "Der gemeinsame Abschluss einer Woche, die wir nie vergessen werden.",
        "icon": <PiSunHorizonBold />
    },
    {
        "day": "Tue",
        "date": "Dienstag, 4. Juni"
    },
    {
        "day": "Tue",
        "time": "08:30 - 10:30",
        "title": "Frühstück",
        "description": "",
        "icon": <PiCoffee />
    },
    {
        "day": "Tue",
        "time": "bis 13:00",
        "title": "Abreise",
        "description": "",
        "icon": <PiAirplaneTakeoffBold />
    }
]


function Schedule(trigger) {
    const currentDate = new Date();

    // Extract day, month, and year from the current date
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Month starts from 0
    const year = currentDate.getFullYear();
    var currentTab = "Thu"
    switch (`${day}/${month}/${year}`) {
        case "30/5/2024":
            currentTab = "Thu"
            break;
        case "31/5/2024":
            currentTab = "Fri"
            break;
        case "1/6/2024":
            currentTab = "Sat"
            break;
        case "2/6/2024":
            currentTab = "Sun"
            break;
        case "3/6/2024":
            currentTab = "Mon"
            break;
        case "4/6/2024":
            currentTab = "Mon"
            break;
        default:
            // Code for any other date
            break;
    }

    const [isSticky, setIsSticky] = useState(false);
    const [top, setTop] = useState(0);
    const [spacerHeight, setSpacerHeight] = useState(0);

//372
    useEffect(() => {

        const handleScroll = () => {
            setSpacerHeight(0)
            let spacer = 0
            if (document.querySelector(".horizontal-nav")) {
                spacer = spacer + document.querySelector(".horizontal-nav").clientHeight
            }
            var headerHeight = document.querySelector("header").clientHeight;
            if (window.scrollY >= (headerHeight)) {
                setIsSticky(true);
                if (document.querySelector(".horizontal-nav-events")) {
                    //setTop(document.querySelector(".horizontal-nav-events").clientHeight - 1)
                    spacer = spacer + document.querySelector(".horizontal-nav-events").clientHeight
                    setTop(spacer - 1)
                }
                setSpacerHeight(spacer)
            } else {
                setIsSticky(false);
                setTop(0)
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setSpacerHeight(0)
        setTop(0)
        setIsSticky(false)
    }, [trigger]);

    function scrollToTop(){
        if(isSticky){
            let calc = top + document.querySelector(".tabheader.sticky").clientHeight + top +5
            window.scrollTo(0,calc)
        }
    }

    return (

        <>
            <Container>
                <Tabs color="#9fbea0" defaultValue={currentTab || "Thu"} onChange={(value) =>scrollToTop()}>
                    <Tabs.List justify="space-evenly" className={isSticky ? "tabheader sticky" : ""} style={{ top: top}}>
                        <Tabs.Tab value="Thu" pl={"xs"} pr={"xs"}>
                            30.05
                        </Tabs.Tab>
                        <Tabs.Tab value="Fri" pl={"xs"} pr={"xs"}>
                            31.05
                        </Tabs.Tab>
                        <Tabs.Tab value="Sat" pl={"xs"} pr={"xs"}>
                            01.06
                        </Tabs.Tab>
                        <Tabs.Tab value="Sun" pl={"xs"} pr={"xs"}>
                            02.06
                        </Tabs.Tab>
                        <Tabs.Tab value="Mon" pl={"xs"} pr={"xs"}>
                            03.06
                        </Tabs.Tab>
                        <Tabs.Tab value="Tue" pl={"xs"} pr={"xs"}>
                            04.06
                        </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="Thu">
                        <section className="section schedule-section" id="agenda">
                            <div className="container">
                                <ul className="activities">
                                    {activityArray ? (
                                        activityArray.filter(element => element.day === "Thu").map((element, index) => (
                                            <ScheduleActivity
                                                time={element.time}
                                                title={element.title}
                                                subtitle={element.subtitle}
                                                description={element.description}
                                                icon={element.icon}
                                                date={element.date}
                                                key={index}
                                            />
                                        ))
                                    ) : null}

                                    {/* Repeat the ScheduleActivity component for other activities */}
                                </ul>
                            </div>
                        </section>
                    </Tabs.Panel>

                    <Tabs.Panel value="Fri">
                        <section className="section schedule-section" id="agenda">
                            <div className="container">
                                <ul className="activities">
                                    {activityArray ? (
                                        activityArray.filter(element => element.day === "Fri").map((element, index) => (
                                            <ScheduleActivity
                                                time={element.time}
                                                title={element.title}
                                                subtitle={element.subtitle}
                                                description={element.description}
                                                icon={element.icon}
                                                date={element.date}
                                                key={index}
                                            />
                                        ))
                                    ) : null}

                                    {/* Repeat the ScheduleActivity component for other activities */}
                                </ul>
                            </div>
                        </section>
                    </Tabs.Panel>

                    <Tabs.Panel value="Sat">
                        <section className="section schedule-section" id="agenda">
                            <div className="container">
                                <ul className="activities">
                                    {activityArray ? (
                                        activityArray.filter(element => element.day === "Sat").map((element, index) => (
                                            <ScheduleActivity
                                                time={element.time}
                                                title={element.title}
                                                subtitle={element.subtitle}
                                                description={element.description}
                                                icon={element.icon}
                                                date={element.date}
                                                key={index}
                                            />
                                        ))
                                    ) : null}

                                    {/* Repeat the ScheduleActivity component for other activities */}
                                </ul>
                            </div>
                        </section>
                    </Tabs.Panel>

                    <Tabs.Panel value="Sun">
                        <section className="section schedule-section" id="agenda">
                            <div className="container">
                                <ul className="activities">
                                    {activityArray ? (
                                        activityArray.filter(element => element.day === "Sun").map((element, index) => (
                                            <ScheduleActivity
                                                time={element.time}
                                                title={element.title}
                                                subtitle={element.subtitle}
                                                description={element.description}
                                                icon={element.icon}
                                                date={element.date}
                                                key={index}
                                            />
                                        ))
                                    ) : null}

                                    {/* Repeat the ScheduleActivity component for other activities */}
                                </ul>
                            </div>
                        </section>
                    </Tabs.Panel>
                    <Tabs.Panel value="Mon">
                        <section className="section schedule-section" id="agenda">
                            <div className="container">
                                <ul className="activities">
                                    {activityArray ? (
                                        activityArray.filter(element => element.day === "Mon").map((element, index) => (
                                            <ScheduleActivity
                                                time={element.time}
                                                title={element.title}
                                                subtitle={element.subtitle}
                                                description={element.description}
                                                icon={element.icon}
                                                date={element.date}
                                                key={index}
                                            />
                                        ))
                                    ) : null}

                                    {/* Repeat the ScheduleActivity component for other activities */}
                                </ul>
                            </div>
                        </section>
                    </Tabs.Panel>
                    <Tabs.Panel value="Tue">
                        <section className="section schedule-section" id="agenda">
                            <div className="container">
                                <ul className="activities">
                                    {activityArray ? (
                                        activityArray.filter(element => element.day === "Tue").map((element, index) => (
                                            <ScheduleActivity
                                                time={element.time}
                                                title={element.title}
                                                subtitle={element.subtitle}
                                                description={element.description}
                                                icon={element.icon}
                                                date={element.date}
                                                key={index}
                                            />
                                        ))
                                    ) : null}

                                    {/* Repeat the ScheduleActivity component for other activities */}
                                </ul>
                            </div>
                        </section>
                    </Tabs.Panel>
                </Tabs>
            </Container>
        </>
    );
}



export default Schedule;
