import { Hearts } from "react-loader-spinner"
function LoadingScreen({ guestStatus, rsvp }) {
    return (
        !guestStatus || rsvp === "init" ? (
            <div className='loading-box'>
                <Hearts height={180} width={380} color='#9fbea0' />
            </div>
        )
            : (
                <div className='loading-box'>
                    {
                        guestStatus === "unknown" ? (
                            <h1>Leider wurde deine Einladung nicht gefunden</h1>
                        )
                            : (
                                <h1>Es ist ein Fehler aufgetreten</h1>
                            )
                    }
                </div>
            )
    )
}
export default LoadingScreen

