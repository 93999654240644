import React from 'react';
import ScheduleActivity from '../fragments/ScheduleActivity';
import Heading from '../fragments/Heading';
function Schedule({ activityArray }) {
    return (
        <>
            <section className="section schedule-section" id="agenda">
                <Heading heading={"Ablauf"} subheading={"Hier findet ihr Informationen zu den einzelnen Programmpunkten unserer Hochzeit."} />
                <div className="container">
                    <ul className="activities">
                        {activityArray ? (

                            activityArray.map((element, index) => ( 
                                <ScheduleActivity
                                    time={element.time}
                                    title={element.title}
                                    description={element.description}
                                    icon={element.icon}
                                    date={element.date}
                                    key={index}
                                />
                            ))
                        ) : null}

                        {/* Repeat the ScheduleActivity component for other activities */}
                    </ul>
                </div>
            </section>
        </>
    );
}



export default Schedule;
