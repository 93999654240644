import React, { useEffect } from 'react';
import Heading from '../../fragments/Heading';
import { useState } from 'react';
import { FaChevronDown, FaPooStorm } from "react-icons/fa";

import { useForm } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { API } from 'aws-amplify';
import { Hearts } from "react-loader-spinner"

import { TextInput, Checkbox, Button, Group, Box, Autocomplete, NumberInput, Text, ActionIcon, Combobox, Select, Switch, Popover, Flex } from '@mantine/core';

import { Notification } from '@mantine/core';

import { BiTrash } from 'react-icons/bi';
import { IconX, IconCheck, IconAlertTriangle, IconQuestionMark } from '@tabler/icons-react';
import Fuse from "fuse.js";
import { notifications } from '@mantine/notifications';

const fuseOptions = {
    keys: ['name'], // Specify which property to search on
    includeScore: true, // Include score in results
    threshold: 0.25
};



function Groceries({ guest }) {
    const [loading, setLoading] = useState(false);
    const [unit, setUnit] = useState("");
    const [availableUnits, setAvailableUnits] = useState([]);

    const [filterForOwnProducts, setFilterForOwnProducts] = useState(false);

    const [showNotification, setShowNotification] = useState(false);

    const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
    const [recommendedProducts, setRecommendedProducts] = useState([]);

    const [availableProducts, setAvailableProducts] = useState([]);
    const [availableProductsFlat, setAvailableProductsFlat] = useState([]);
    const [requestedGroceriesRoom, setRequestedGroceriesRoom] = useState([]);
    const [requestedGroceriesList, setRequestedGroceriesList] = useState([]);

    const submitAvailable = false

    useEffect(() => {
        // Set the viewport meta tag dynamically
        const setViewportMetaTag = () => {
            const viewportMetaTag = document.querySelector('meta[name="viewport"]');
            if (viewportMetaTag) {
                viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
            }
        };

        setViewportMetaTag(); // Call it initially

        // Clean up function to remove event listeners
        return () => {
            // You might not need this depending on your application setup
            // Remove the viewport meta tag when component unmounts
            const viewportMetaTag = document.querySelector('meta[name="viewport"]');
            if (viewportMetaTag) {
                viewportMetaTag.remove();
            }
        };
    }, []);



    const form = useForm({
        initialValues: {
            name: '',
            quantity: '',
            unit: '',
        },

    });
    function refresh() {
        console.log("ref")
        API.get("WeddingApi", "/groceries?id=" + guest.id)
            .then(response => {
                //Set the autocoplete data
                let uniqueNamesMap = {};
                response.forEach(item => {
                    if (!uniqueNamesMap[item.name] || item.category) {
                        uniqueNamesMap[item.name] = item;
                    }
                });
                let groupedGroceries = Object.values(uniqueNamesMap).reduce((acc, item) => {
                    if (!acc[item.category]) {
                        if (!item.category) {
                            item.category = "Sonstiges"
                        }
                        acc[item.category] = new Set();
                    }
                    acc[item.category].add(item.name);
                    return acc;
                }, {});

                let productArray = Object.entries(groupedGroceries).map(([group, items]) => ({
                    group,
                    items: Array.from(items)
                }));
                setAvailableProducts(productArray)

                //create a flat array of products to map the unit later on
                let uniqueNamesAndUnits = Array.from(new Set(response.map(item => JSON.stringify({ name: item.name, unit: item.unit })))).map(JSON.parse);
                setAvailableProductsFlat(uniqueNamesAndUnits)

                //create the requested groceries array to display the table
                //let requestedItems = response.filter(item => item.type === "requested" && item.room === guest.room);

                let requestedItemsRoom = response
                    .filter(item => item.type === "requested" && item.room === guest.room)


                setRequestedGroceriesRoom(requestedItemsRoom)
                let uniqueUnits = [...new Set(response.map(item => item.unit).filter(unit => unit !== undefined))]
                    .map(unit => ({ value: unit, label: unit }));
                uniqueUnits.push({ value: "", label: "", disabled: true })
                uniqueUnits.sort((a, b) => a.label.localeCompare(b.label))
                setAvailableUnits(uniqueUnits)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        setUnit(availableProductsFlat.filter(element => element.name.toLowerCase() === form.values.name.trim().toLowerCase())[0]?.unit)
    }, [availableProductsFlat, form.values]);

    useEffect(() => {
        setLoading(true)
        refresh()
    }, []);


    useEffect(() => {
        if (!autoCompleteOpen) {
            const fuse = new Fuse(availableProductsFlat, fuseOptions);
            let fuzzingResults = fuse.search(form.values.name.trim())
            if (fuzzingResults.length > 0 && !(availableProductsFlat.find(item => item.name.toLowerCase() === form.values.name.trim().toLowerCase()))) {
                setRecommendedProducts(fuzzingResults)
                return
            }
            else {
                setRecommendedProducts([])
            }
        }
        else {
            setRecommendedProducts([])
        }
    }, [autoCompleteOpen, availableProductsFlat, form.values.name]);

    useEffect(() => {
    }, []);

    useEffect(() => {
        const groceriesList = requestedGroceriesRoom
            .reduce((acc, item) => {
                // Check if the product already exists in the accumulator
                let existingProduct = acc.find(product => product.name === item.name);
                // If the product doesn't exist, add it to the accumulator
                if (!existingProduct) {
                    acc.push({
                        name: item.name,
                        totalQuantity: parseFloat(item.quantity),
                        requestedQuantity: item.requester === guest.id ? parseFloat(item.quantity) : 0,
                        requestedIds: item.requester === guest.id ? [item.id] : [],
                        unit: item.unit
                    });
                } else {
                    // If the product exists, update its total quantity and requested quantity
                    existingProduct.totalQuantity += parseFloat(item.quantity);
                    if (item.requester === guest.id) {
                        existingProduct.requestedQuantity += parseFloat(item.quantity);
                        existingProduct.requestedIds.push(item.id);
                    }
                    // If the id is not already added, add it to requestedIds
                    if (item.requester === guest.id && !existingProduct.requestedIds.includes(item.id)) {
                        existingProduct.requestedIds.push(item.id);
                    }
                }

                return acc;
            }, [])
            .filter(item => !filterForOwnProducts || item.requestedQuantity > 0)
            .sort((a, b) => a.name.localeCompare(b.name))
        setRequestedGroceriesList(groceriesList)
    }, [filterForOwnProducts, guest.id, requestedGroceriesRoom]);


    function resetForm(values) {
        setRecommendedProducts([])
        setUnit("")
        form.reset()
    }

    function changeFromValue(value) {
        form.setFieldValue('name', value.name)
        form.setFieldValue('unit', value.unit)
    }

    function removeRequestedGroceries(requests) {
        console.log(requests);
        let notificationId = `submit-notification-${(new Date()).getTime()}`
        notifications.show({
            id: notificationId,
            loading: true,
            title: 'Lädt',
            message: 'Wir streichen dein Produkt von unserer Liste',
            autoClose: false,
            withCloseButton: false,
            color: '#9fbea0'
        });
        let deletePromises = requests.map(request =>
            API.del("WeddingApi", "/groceries/object/" + request)
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.error(error);
                    return Promise.reject(error); // Propagating the error by rejecting the promise
                })
        );

        // Wait for all delete requests to complete before resetting the form and refreshing
        Promise.all(deletePromises).then(() => {
            notifications.update({
                id: notificationId,
                loading: false,
                autoClose: 2000,
                icon: <IconCheck />,
                message: "Dein Produkt wurde gelöscht",
                title: "Danke",
                color: '#9fbea0'
            })
            form.reset();
            refresh();
        })
            .catch(error => {
                notifications.update({
                    id: notificationId,
                    loading: false,
                    autoClose: 5000,
                    icon: <IconX />,
                    message: "Da hat etwas nicht funktioniert, versuche es nochmal oder melde dich bei uns!",
                    title: "Upps",
                    color: 'red',
                })
                form.reset();
                refresh();
            });
    }

    function submitForm(values) {
        setRecommendedProducts([])
        let formObject = {
            type: "requested",
            requester: guest.id,
            name: availableProductsFlat.filter(element => element.name.toLowerCase() === form.values.name.trim().toLowerCase())[0]?.name || values.name,
            quantity: values.quantity,
            unit: unit || values.unit,
            room: guest.room
        }
        let notificationId = `submit-notification-${(new Date()).getTime()}`
        notifications.show({
            id: notificationId,
            loading: true,
            title: 'Lädt',
            message: `Wir schreiben ${formObject.name} auf unsere Liste`,
            autoClose: false,
            withCloseButton: false,
            color: '#9fbea0'
        });

        API.put("WeddingApi", "/groceries", { body: formObject })
            .then(response => {
                console.log(response)
                form.reset()
                refresh()
                notifications.update({
                    id: notificationId,
                    loading: false,
                    autoClose: 2000,
                    icon: <IconCheck />,
                    message: `${formObject.name} wurde hinzugefügt`,
                    title: "Danke",
                    color: '#9fbea0'
                })
            })
            .catch(error => {
                console.log("erros");
                notifications.update({
                    id: notificationId,
                    loading: false,
                    autoClose: 5000,
                    icon: <IconX />,
                    message: "Da hat etwas nicht funktioniert, versuche es nochmal oder melde dich bei uns!",
                    title: "Upps",
                    color: 'red',
                })
            })


    }

    return (
        <>
            <div className="container">
                <div className="invitation-content" style={{ marginBottom: "20px" }}>
                    Hier findest du die Einkaufsliste für die Wohnung {guest?.room}
                </div>
                {
                    !loading ? (
                        <>
                            <Group justify="flex-end" mt="md" style={{ marginBottom: "5px" }}>
                                <Switch
                                    checked={filterForOwnProducts}
                                    onChange={(event) => setFilterForOwnProducts(event.currentTarget.checked)}
                                    color='#9fbea0'
                                    label="Nur meine Einkäufe"
                                />
                            </Group>
                            <DataTable
                                minHeight={150}
                                style={{ "margin-bottom": '1.5rem', }}
                                idAccessor="id"
                                withTableBorder
                                borderRadius="sm"
                                withColumnBorders
                                striped
                                highlightOnHover
                                noRecordsText='Es wurden noch keine Produkte hinzugefügt'
                                // provide data
                                records={requestedGroceriesList || []}
                                // define columns
                                columns={[
                                    {
                                        accessor: 'name',
                                        title: 'Produkt',
                                        render: ({ name, unit }) => (
                                            `${name} ${unit ? "(" + unit + ")" : ""}`
                                        )
                                    },
                                    {
                                        accessor: 'quantity',
                                        textAlign: 'center',
                                        title: 'meins / gesamt',
                                        render: ({ name, requestedQuantity, totalQuantity, requestedIds }) => (
                                            requestedQuantity > 0 ?
                                                <>
                                                    <Group justify='center'>
                                                        {requestedQuantity} / {totalQuantity}
                                                        <ActionIcon size="sm" variant="transparent" c="dimmed" onClick={() => removeRequestedGroceries(requestedIds)}>
                                                            <BiTrash />
                                                        </ActionIcon>
                                                    </Group>
                                                </>

                                                : (`${requestedQuantity} / ${totalQuantity}`)
                                        ),

                                    },


                                ]}

                            />
                            <hr style={{ width: "90%", borderColor: '#9fbea0' }} />
                            <Box mx="auto">
                                <form onSubmit={form.onSubmit((values) => {
                                    if (!(values.unit || unit)) {
                                        setShowNotification(false)
                                        notifications.show({
                                            icon: <IconAlertTriangle />,
                                            message: "Bitte wähle eine Einheit aus",
                                            title: "Upps",
                                            color: '#FFBF00'
                                        })
                                        return;
                                    }
                                    submitForm(values);
                                })}>
                                    {
                                        submitAvailable ? (
                                            <>
                                                <Group justify='center'>
                                                    <Autocomplete
                                                        mt="sm"
                                                        w={"45%"}
                                                        label="Produkt"
                                                        placeholder=""
                                                        data={availableProducts}
                                                        required
                                                        {...form.getInputProps('name')}
                                                        onClick={() =>
                                                            resetForm()
                                                        }
                                                        comboboxProps={{ readOnly: true, onOpen: () => setAutoCompleteOpen(true), onClose: () => setAutoCompleteOpen(false), position: 'bottom', middlewares: { flip: false } }}

                                                    />
                                                    <Select required disabled={unit} placeholder={unit} w={"25%"} mt="sm" label={"Einheit"} data={availableUnits || []} {...form.getInputProps('unit')} />
                                                    <NumberInput inputMode='decimal' hideControls w={"20%"} mt="sm" label={"Menge"} required allowedDecimalSeparators={[",", "."]} {...form.getInputProps('quantity')} />
                                                </Group>



                                                <Group justify="flex-end" mt="md">
                                                    <Button type="submit" color='#9fbea0'>Absenden</Button>
                                                    <Button
                                                        variant="outline"
                                                        color='red'
                                                        onClick={() =>
                                                            resetForm()
                                                        }
                                                    >
                                                        Löschen
                                                    </Button>
                                                </Group>
                                            </>
                                        ) : (
                                            <Notification title={"Wir waren schon einkaufen!"} color={'#9fbea0'} withCloseButton={false}>
                                                <Text>Aktuell kannst du leider nichts hinzufügen</Text>
                                            </Notification>
                                        )
                                    }
                                </form>

                            </Box>
                        </>
                    ) : (
                        <div className='loading-box insite'>
                            <Hearts height={180} width={380} color='#9fbea0' />
                        </div>
                    )
                }
                {
                    recommendedProducts.length > 0 ? (
                        <Notification onClose={() => { setRecommendedProducts([]) }} icon={<IconQuestionMark />} title={"Meintest du vielleicht?"} color={'#9fbea0'}>
                            <Group gap={"xs"} justify="flex-start">
                                {recommendedProducts.map((grocery, index) => (
                                    <Button variant='outline' color='#9fbea0' key={index} onClick={() => changeFromValue(grocery.item)}>
                                        {grocery.item.name}
                                    </Button>
                                ))}
                            </Group>
                        </Notification>
                    ) : (null)
                }
            </div>
        </>
    );
}



export default Groceries;
