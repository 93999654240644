import React, { useEffect, useState } from 'react';
import Heading from '../fragments/Heading';
import { API } from 'aws-amplify';

const roomRateA = 50
const roomRateB = 40
const breakfastRate = 15

function RSVPForm({ guest, rsvp }) {
    let EUR = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })


    const [formData, setFormData] = useState({
        id: guest.id,
        name: guest?.name,
        email: '',
        phone: '',
        guests: '', // Default value for the number of guests
        guestsDetail: {},
        /*: {
            ceremony: false,
            reception: false,
            italy: false,
        },*/
        attendance: "init",
        comment: "",
        italy: {
            roomprice: 0,
            breakfastprice: 0,
            totalprice: 0,
        },
    });
    const [formHidden, setFormHidden] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(true)

    /* TODO: Disable form when RSVP is true */
    useEffect(() => {
        if (rsvp === true) {
            setFormHidden(true)
        }
        else {
            setFormHidden(false)
        }
    }, [rsvp]);


    useEffect(() => {
        setFormData({
            ...formData,
            "name": guest?.name,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guest]);


    useEffect(() => {
        let roomPrice = 0
        let breakfastPrice = 0
        let totalPrice = 0
        Object.entries(formData.guestsDetail)
            .filter(([key, guest]) => guest.italy === true && guest.days)
            .map(([key, guest], index) => {
                let rate = 0
                if (guest.days === "3") {
                    rate = roomRateA
                }
                if (guest.days === "5") {
                    rate = roomRateB
                }
                roomPrice = roomPrice + (guest?.days * rate);
                if (guest.breakfast === true) {
                    breakfastPrice = breakfastPrice + (guest.days * breakfastRate)
                }
            });

        totalPrice = roomPrice + breakfastPrice
        setFormData({
            ...formData,
            italy: {
                ...formData.italy,
                "roomprice": roomPrice,
                "breakfastprice": breakfastPrice,
                "totalprice": totalPrice,
            }
        });
    }, [formData, formData.guestsDetail]);

    const handleInputChange = (e) => {
        const { name, value, type, checked, id } = e.target;
        if (id.startsWith("events_")) {
            setFormData({
                ...formData,
                events: {
                    ...formData.events,
                    [name]: checked,
                },
            });
        } else if (id.startsWith("guestsDetail_")) {
            if (type === "checkbox") {
                setFormData({
                    ...formData,
                    guestsDetail: {
                        ...formData.guestsDetail,
                        [id]: {
                            ...formData.guestsDetail[id],
                            [name]: checked

                        },
                    },
                });
            }
            else {
                let calculatedValue = value
                if (value === "true") {
                    calculatedValue = true
                }
                else if (value === "false") {
                    calculatedValue = false
                }
                setFormData({
                    ...formData,
                    guestsDetail: {
                        ...formData.guestsDetail,
                        [id]: {
                            ...formData.guestsDetail[id],
                            [name]: calculatedValue

                        },
                    },
                });
            };


        } else if (id.startsWith("italy_")) {
            if (type === "checkbox") {
                setFormData({
                    ...formData,
                    italy: {
                        ...formData.italy,
                        [name]: checked,
                    },

                })
            }
            else {
                setFormData({
                    ...formData,
                    italy: {
                        ...formData.italy,
                        [name]: value,
                    },
                })
            };
        } else if (id === "attendance") {
            setFormData({
                ...formData,
                attendance: value === 'true' ? true : (false)
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let validated = true
        Object.values(formData.guestsDetail).forEach((detail) => {
            if (!(detail.ceremony) && !(detail.italy) && !(detail.reception)) {
                alert(`Bitte wähle für ${detail.name} eine Zusage aus oder reduziere die teilnehmenden Gäste`)
                validated = false
                return
            }
        });
        if (validated === true) {
            setFormHidden(true)
            API.put("WeddingApi", "/rsvp", { body: formData })
                .then(response => {
                })
                .catch(error => {
                    setSubmitSuccess(false)
                })
        }
    };

    return (
        <>
            <section id="rsvp" className="section rsvp">
                <Heading heading={"RSVP"} subheading={"Bitte sagt uns bescheid ob ihr kommt"} />
                <div className="container">
                    <form onSubmit={handleSubmit} className={`rsvp-form needs-validation ${formHidden ? 'hidden' : ''}`}>
                        <div>
                            <div className='form-group radio'>
                                <div>
                                    <input
                                        type="radio"
                                        id="attendance"
                                        name="attendance"
                                        value={true}
                                        checked={formData.attendance !== "init" && formData.attendance}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="attending">{`${guest.allowedTotalGuests.toString() === "1" ? "Ich bin dabei!" : "Wir sind dabei!"}`}</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        id="attendance"
                                        name="attendance"
                                        value={false}
                                        checked={formData.attendance !== "init" && !formData.attendance}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="notAttending">{`${guest.allowedTotalGuests.toString() === "1" ? "Ich bin leider" : "Wir sind leider"}`} <strong>nicht</strong> dabei!</label>
                                </div>
                            </div>
                            <div className='form-group'>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    disabled
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className='form-control'
                                />
                            </div>
                            <div className='form-group'>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder='Email Adresse'
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className='form-control'

                                />
                            </div>
                            <div className='form-group'>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    placeholder='Telefonnummer'
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    required
                                    className='form-control'
                                />
                            </div>
                            {
                                formData.attendance !== "init" && formData.attendance ? (
                                    <>
                                        <div className='form-group'>
                                            <select
                                                id="guests"
                                                name="guests"
                                                value={formData.guests}
                                                onChange={handleInputChange}
                                                className='form-control'
                                                required
                                            >
                                                <option value="" disabled>
                                                    Anzahl der Gäste
                                                </option>
                                                {
                                                    guest && guest.allowedTotalGuests ? (
                                                        guest.allowedTotalGuests === 1 ? (
                                                            <option value={1}>1</option>
                                                        )
                                                            : (
                                                                [...Array(parseInt(guest.allowedTotalGuests))].map((x, i) =>
                                                                    <option key={i} value={i + 1}>{i + 1}</option>
                                                                )
                                                            )

                                                    )
                                                        : (null)
                                                }
                                            </select>
                                        </div>
                                        <div className='form-group guestnames'>
                                            {
                                                formData.guests && formData.guests !== 0 ? (
                                                    <table>
                                                        <tbody>
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <td></td>
                                                                {guest && guest.invitedEvents.ceremony ? (
                                                                    <td>Standesamt</td>
                                                                )
                                                                    : (null)
                                                                }
                                                                {guest && guest.invitedEvents.party ? (
                                                                    <td>Feier</td>
                                                                )
                                                                    : (null)
                                                                }
                                                                {guest && guest.invitedEvents.italy ? (
                                                                    <td>Toskana</td>
                                                                )
                                                                    : (null)
                                                                }
                                                            </tr>
                                                            {
                                                                [...Array(parseInt(formData.guests))].map((x, i) =>
                                                                    <tr key={`gd_${i}`}>
                                                                        <td>
                                                                            <input
                                                                                type="text"
                                                                                key={`guestsDetail_${i + 1}`}
                                                                                id={`guestsDetail_${i + 1}`}
                                                                                name={`name`}
                                                                                placeholder={`Name des ${i + 1}. Gastes`}
                                                                                value={formData.guestsDetail[`guestsDetail_${i + 1}`]?.name || ""}
                                                                                onChange={handleInputChange}
                                                                                required
                                                                                className='form-control'
                                                                            />
                                                                        </td>
                                                                        {
                                                                            guest && guest.invitedEvents.ceremony ? (
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    <div className='form-group'>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id={`guestsDetail_${i + 1}`}
                                                                                            name="ceremony"
                                                                                            checked={formData.guestsDetail[`guestsDetail_${i + 1}`]?.ceremony}
                                                                                            onChange={handleInputChange}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            )
                                                                                : (null)
                                                                        }
                                                                        {
                                                                            guest && guest.invitedEvents.party ? (
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    <div className='form-group'>

                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id={`guestsDetail_${i + 1}`}
                                                                                            name="reception"
                                                                                            checked={formData.guestsDetail[`guestsDetail_${i + 1}`]?.reception}
                                                                                            onChange={handleInputChange}

                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            )
                                                                                : (null)
                                                                        }
                                                                        {
                                                                            guest && guest?.invitedEvents?.italy ? (
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    <div className='form-group'>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id={`guestsDetail_${i + 1}`}
                                                                                            name="italy"
                                                                                            checked={formData.guestsDetail[`guestsDetail_${i + 1}`]?.italy}
                                                                                            onChange={handleInputChange}

                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            )
                                                                                : (null)
                                                                        }
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>

                                                )
                                                    : (null)
                                            }
                                        </div>
                                        {/*
                                        <div className='form-group checkbox'>
                                            {
                                                guest && guest.invitedEvents.includes("sa") ? (
                                                    <div className='form-group'>
                                                        <input
                                                            type="checkbox"
                                                            id="events_ceremony"
                                                            name="ceremony"
                                                            checked={formData.events.ceremony}
                                                            onChange={handleInputChange}
                                                            disabled={formData.attendance === "No"}
                                                        />
                                                        <label htmlFor="ceremony">Standesamt</label>
                                                    </div>
                                                )
                                                    : (null)
                                            }
                                            {
                                                guest && guest.invitedEvents.includes("party") ? (
                                                    <div className='form-group'>
                                                        <input
                                                            type="checkbox"
                                                            id="events_reception"
                                                            name="reception"
                                                            checked={formData.events.reception}
                                                            onChange={handleInputChange}
                                                            disabled={formData.attendance === "No"}

                                                        />
                                                        <label htmlFor="reception">Party</label>
                                                    </div>
                                                )
                                                    : (null)
                                            }
                                            {
                                                guest && guest.invitedEvents.includes("italy") ? (
                                                    <div className='form-group'>
                                                        <input
                                                            type="checkbox"
                                                            id="events_italy"
                                                            name="italy"
                                                            checked={formData.events.italy}
                                                            onChange={handleInputChange}
                                                            disabled={formData.attendance === "No"}

                                                        />
                                                        <label htmlFor="italy">Italien</label>
                                                    </div>
                                                )
                                                    : (null)
                                            }
                                        </div>
                                        <div className='form-group checkbox'>
                                            {
                                                formData.events.italy ? (
                                                    <div className='form-group subcheckbox'>
                                                        <input
                                                            type="checkbox"
                                                            id="italy_breakfast"
                                                            name="breakfast"
                                                            checked={formData.italy.breakfast}
                                                            onChange={handleInputChange}
                                                        //disabled={formData.attendance === "No"}
                                                        />
                                                        <label htmlFor="breakfast">Frühstück</label>
                                                    </div>
                                                )
                                                    : (null)

                                            }
                                        </div>

                                        {/*
                                        <div className='form-group comment'>
                                            <textarea
                                                type="text"
                                                id="comment"
                                                name="comment"
                                                onChange={handleInputChange}
                                                rows={5}
                                            />
                                        </div>
                                        */}
                                        {
                                            /* Display costs for Italy

                                            formData.events.italy === true && formData.guests !== 0 ? (
                                                <div className='form-group price'>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Übernachtung</td>
                                                                <td className='form-group price tablecell amount'>{EUR.format(formData.italy.roomprice)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Frühstück</td>
                                                                <td className='form-group price tablecell amount'>{EUR.format(formData.italy.breakfastprice)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Gesamt</b></td>
                                                                <td className='form-group price tablecell amount'><b>{EUR.format(formData.italy.totalprice)}</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                                : (null)
                                            */}
                                        {
                                            Object.values(formData.guestsDetail).some(detail => detail.italy === true) ? (
                                                <div className='form-group italydetail'>
                                                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                                                        Toskana
                                                    </div>
                                                    <table>
                                                        <tbody>
                                                            {
                                                                Object.entries(formData.guestsDetail).filter(([key, guest]) => guest.italy === true).map(([key, guest], index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {guest.name || `Gast ${index + 1}`}
                                                                        </td>
                                                                        <td>

                                                                            <select
                                                                                id={`${key}`}
                                                                                name="days"
                                                                                required
                                                                                value={formData.guestsDetail[key]?.days || ""}
                                                                                onChange={handleInputChange}
                                                                                className='form-control'                                            >
                                                                                <option value="" disabled>
                                                                                    Anzahl der Nächte
                                                                                </option>
                                                                                <option value="0">
                                                                                    Keine Übernachtung
                                                                                </option>
                                                                                <option value="3">
                                                                                    3 Nächte
                                                                                </option>
                                                                                <option value="5">
                                                                                    5 Nächte
                                                                                </option>
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <select
                                                                                id={`${key}`}
                                                                                name="breakfast"
                                                                                required
                                                                                value={
                                                                                    formData.guestsDetail[key]?.breakfast
                                                                                        ? "true"
                                                                                        : formData.guestsDetail[key]?.breakfast === false
                                                                                            ? "false"
                                                                                            : ""
                                                                                }
                                                                                onChange={handleInputChange}
                                                                                className='form-control'
                                                                            >
                                                                                <option value="" disabled>
                                                                                    Frühstück (15€ pro Tag)
                                                                                </option>
                                                                                <option value={true}>
                                                                                    Mit Frühstück
                                                                                </option>
                                                                                <option value={false}>
                                                                                    Ohne Frühstück
                                                                                </option>
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                ))

                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div className='form-group price'>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Übernachtung</td>
                                                                    <td className='form-group price tablecell amount'>{EUR.format(formData.italy.roomprice)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Frühstück</td>
                                                                    <td className='form-group price tablecell amount'>{EUR.format(formData.italy.breakfastprice)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Gesamt</b></td>
                                                                    <td className='form-group price tablecell amount'><b>{EUR.format(formData.italy.totalprice)}</b></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            ) : (null)

                                        }
                                    </>
                                )
                                    : (
                                        null
                                    )
                            }

                        </div>
                        <button type="submit" className='btn btn-block submit-button'>Absenden!</button>
                    </form>
                    <div className={`rsvp-response alert alert-${submitSuccess ? 'success' : 'failure'} ${!formHidden ? 'hidden' : ''}`}>
                        {
                            submitSuccess ? (
                                <>
                                    <h2>Vielen Dank für deine Rückmeldung</h2>
                                    <p>Solltest du deine Rückmeldung ändern wollen, melde dich bitte bei uns</p>
                                </>
                            ) : (
                                <>
                                    <h2>Leider gab es ein Problem bei der Übermittlung</h2>
                                    <p>Versuche es später noch mal oder kontaktiere uns direkt</p>
                                </>
                            )
                        }

                    </div>

                </div>

            </section>
        </>
    );
}

export default RSVPForm;
