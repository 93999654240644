import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LinkWithQuery } from "../fragments/LinkWithQuery";
function NavBar(trigger) {
    const [isSticky, setIsSticky] = useState(false);
    const [top, setTop] = useState(0);
    const [spacerHeight, setSpacerHeight] = useState(0);

    const location = useLocation();
    const { hash, pathname, search } = location;

    useEffect(() => {

        const handleScroll = () => {
            setSpacerHeight(0)
            let spacer = 0
            if (document.querySelector(".horizontal-nav")) {
                spacer = spacer + document.querySelector(".horizontal-nav").clientHeight
            }
            var headerHeight = document.querySelector("header").clientHeight;
            if (window.scrollY >= (headerHeight)) {
                setIsSticky(true);
                if (document.querySelector(".horizontal-nav-events")) {
                    setTop(document.querySelector(".horizontal-nav-events").clientHeight - 1)
                    spacer = spacer + document.querySelector(".horizontal-nav-events").clientHeight
                }
                setSpacerHeight(spacer)
            } else {
                setIsSticky(false);
                setTop(0)
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        //set scroll padding to align anchors below nav bar
        let spacer = 0
        if (document.querySelector(".horizontal-nav-events")) {
            spacer = spacer + document.querySelector(".horizontal-nav-events").clientHeight
        }
        if (document.querySelector(".horizontal-nav")) {
            spacer = spacer + document.querySelector(".horizontal-nav").clientHeight
        }
        document.documentElement.style.cssText = "--scrollPaddingTop: " + spacer + "px";
    }, []);

    useEffect(() => {
        setSpacerHeight(0)
        setTop(0)
        setIsSticky(false)
    }, [trigger]);

    return (
        <>
            <nav className={`horizontal-nav ${isSticky ? 'sticky' : ''}`} style={{ top: top }} id="navbar">
                <ul>
                    {
                        <>
                            <li><LinkWithQuery className={pathname === "/italy" || pathname === "/" ? "active" : ""} to="/italy">Home</LinkWithQuery></li>
                            <li><LinkWithQuery className={pathname.includes("/schedule") ? "active" : ""} to="/italy/schedule">Ablauf</LinkWithQuery></li>
                            <li><LinkWithQuery className={pathname.includes("/faq") ? "active" : ""} to="/italy/faq">FAQ</LinkWithQuery></li>
                            <li><LinkWithQuery className={pathname.includes("/italy/groceries") ? "active" : ""} to="/italy/groceries">Einkauf</LinkWithQuery></li>
                        </>
                    }
                    { /*
                    <li><a href="location">Location</a></li>
                    <li><a href="schedule">Ablauf</a></li>
                    <li><a href="rsvp">RSVP</a></li>
                    <li><a href="faq">FAQ</a></li>
                    */
                    }
                </ul>
            </nav>
            <div style={{ height: spacerHeight }}>
                &nbsp;
            </div>
        </>
    );
}

export default NavBar