import React, { useContext, useEffect } from 'react';
import { guestDataContext } from '../contexts/GuestDataContext';
import Invitation from '../components/Invitation';
import Header from '../components/Header';
import LoadingScreen from '../fragments/LoadingScreen';
import RSVPForm from '../components/RsvpForm';
import Organizers from '../components/Organizers';
import Footer from '../components/Footer';

function InvitationPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { guest, rsvp } = useContext(guestDataContext);
    return (
        guest && guest.status === "success" && rsvp !== "init" ? (
            <div>
                <header>
                    <Header countdownEnd={'May 4, 2024 00:00:00'} />
                </header>
                <main>
                    <Invitation guest={guest} />
                    <Organizers />
                    {

                        guest && guest.invitedEvents.italy ?
                            (
                                null
                            )
                            : (null)
                    }
                    <Footer/>
                </main>
            </div>
        )
            : (
                <LoadingScreen guestStatus={guest?.status} rsvp={rsvp} />
            )
    );
}

export default InvitationPage;
