import React, { useContext, useEffect, useState } from 'react';
import Invitation from '../components/Invitation';
import Locations from '../components/Locations';
import Schedule from '../components/Schedule';
import Header from "../components/Header"
import RSVPForm from '../components/RsvpForm';
import NavBar from '../components/NavBar';
import NavBarEvents from '../components/NavBarEvents';
import LoadingScreen from '../fragments/LoadingScreen';
import { guestDataContext } from '../contexts/GuestDataContext';
import Footer from '../components/Footer';
import locationMarina from '../content/location_Marina.jpg'
import { FaHome } from "react-icons/fa";
import { BiDrink } from "react-icons/bi";
import { GiLinkedRings } from "react-icons/gi";
import { LuPartyPopper, LuSun } from "react-icons/lu";
import { PiCoffee } from "react-icons/pi";
import { MdPool } from "react-icons/md";
import { PiWineFill } from "react-icons/pi";
import { FaBus } from "react-icons/fa";
import { PiAirplaneTakeoffBold } from "react-icons/pi";
import EventInfo from '../components/EventInfo';
import FAQ from '../components/FAQ';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { NavigateWithQuery } from '../fragments/NavigateWithQuery';
var locations = [
    {
        "name": `Amici e Famiglia: Fünf Tage, ein Versprechen`,
        "location": "Località Poggiauto",
        "address": "Laterina Pergine Valdarno, 52019 Arezzo, Italien",
        "image": locationMarina,
        "mapLink": "https://www.google.com/maps/place/Agriturismo+Antiche+Dimore+di+Poggianto/@43.4958019,11.6873914,16.38z/data=!4m9!3m8!1s0x132bc038e011324f:0xbd11e7f973967291!5m2!4m1!1i2!8m2!3d43.494898!4d11.687457!16s%2Fg%2F1tg3d6p6?entry=ttu"
    }
]

var activitiesAll = [
    {
        "date": "Donnerstag, 30. Mai"
    },
    {
        "time": "",
        "title": "Anreise",
        "description": "",
        "icon": <FaHome />
    },
    {
        "time": "",
        "title": "Welcome Drinks",
        "description": "",
        "icon": <BiDrink />
    },
    {
        "date": "Freitag, 31. Mai"
    },
    {
        "time": "16:00",
        "title": "Freie Trauung",
        "description": "",
        "icon": <GiLinkedRings />
    },
    {
        "time": "",
        "title": "Hochzeitsfeier",
        "description": "",
        "icon": <LuPartyPopper />
    },
    {
        "date": "Samstag, 1. Juni"
    },
    {
        "time": "10:30",
        "title": "Katerfrühstück",
        "description": "",
        "icon": <PiCoffee />
    },
    {
        "time": "",
        "title": "Gemeinsamer Tag am Pool",
        "description": "",
        "icon": <LuSun />
    },
    {
        "date": "Sonntag, 2. Juni"
    },
    {
        "time": "",
        "title": "Chianti Region entdecken - Tasting",
        "description": "Weitere Informationen dazu folgen im März",
        "icon": <PiWineFill />
    },
    {
        "date": "Montag, 3. Juni"
    },
    {
        "time": "",
        "title": "Tagesausflug nach Florenz",
        "description": "Weitere Informationen dazu folgen im März",
        "icon": <FaBus />
    },
    {
        "date": "Dienstag, 4. Juni"
    },
    {
        "time": "11:00",
        "title": "Abreise",
        "description": "",
        "icon": <PiAirplaneTakeoffBold />
    }

]
var faq = [
    {
        "title": "Ich möchte mit dem Auto anreisen? Wie komme ich zum Hof und wo kann ich mein Auto parken?",
        "description":
            <>
                Die Adresse von Marina lautet:<br />
                Località Poggiauto<br />
                Laterina Pergine Valdarno<br />
                52019 Arezzo, Italien
                <br /><br />
                Von Köln aus sind es ca. 1.200km und etwa 13h Fahrzeit.<br />
                Der letzte Kilometer führt Euch über eine unbefestigte Straße. Ein Besuch in der Waschstraße lohnt sich also erst im Nachgang. Kostenlose Parkplätze gibt es ausreichend auf dem Gelände.
                <br /><br />
                Keine Sorge ihr braucht Euch nicht zu streiten, wer neben der Schachtel parken darf. Sie darf zuhause bleiben. :)
            </>
    },
    {
        "title": "Ich möchte lieber fliegen. Wie komme ich am besten ans Ziel?",
        "description":
            <>
                Die Stadt Arezzo hat einen Privatflugplatz mit dem Kürzel QZO. Von da aus sind es ca. 15 Minuten mit dem Auto zur Location. Falls Dein Jet zur Zeit in Wartung sein sollte, empfehlen wir Dir folgende internationalen Flughäfen in der Nähe.
                <br /> <br />
                Flughafen Florenz (FLR) - 77km - 1h<br />
                Flughafen Pisa (PSA) - 144km - 1h 45m<br />
                Flughafen Bologna (BLQ) - 166km - 2h
                <br /> <br />
                Die Lufthansa bietet die Verbindung Frankfurt - Florenz täglich zwei Mal an. Über das Zug-zum-Flug-Angebot der Lufthansa kommt Ihr günstig und schnell zum Flughafen.
                Am 30.05 und 04.06. bietet Eurowings die Verbindung Düsseldorf - Florenz an.
                Wer bereit ist umzusteigen, kann günstiger und zeitlich flexibler reisen.
                <br /> <br />
                Die beiden römischen Flughäfen sind eine weitere Alternative, allerdings dann etwas weiter  von der Location entfernt.
                <br /> <br />
                Flughafen Rom-Ciampino (CIA) - 240km - 2h 30m<br />
                Flughafen Rom-Fiumicino (FCO) - 258km - 2h 45m
            </>
    },
    {
        "title": "Ich möchte mit der Bahn anreisen. Geht das auch?",
        "description":
            <>
                Das ist natürlich auch möglich. Der nächste große Bahnhof zum Hof ist Arezzo.<br />
                <br /> <br />
                Von Köln aus dauert die Strecke mit dem Zug ca. 14h und somit nur eine Stunde länger als mit dem Auto. <a href='https://www.lefrecce.it/Channels.Website.WEB/#/'>Hier</a> findet Ihr die Verbindungen über den italienischen Anbieter
                <br /> <br />
                Von München Hbf kommt man mit dem Nachtzug bis nach Bologna und von dort aus mit dem Schnellzug nach Arezzo in ca. 11h 40min.<br />
                Wer nicht gern über Nacht reist, kann die Strecke auch in unter 9h am Tag schaffen. Die Kosten betragen aktuell unter € 40 pro Strecke.<br />
                <br /> <br />
                Auch Teilstrecken mit dem Zug sind möglich:<br />
                Florenz Flughafen - Ponticino: ca. 1h 50 m<br />
                Florenz Flughafen - Arezzo: ca. 1h<br />

            </>
    },
    {
        "title": "Ich möchte mir vor Ort keinen Mietwagen nehmen. Wie komme ich zur Location?",
        "description":
            <>
                Das verstehen wir und haben dafür eine Lösung. Gerne überbrücken wir für Euch die bekannte letzte Meile. Für die konkrete Planung warten wir Eure Rückmeldungen zur An- und Abreise ab. Abholmöglichkeiten bestehen an den Bahnhöfen im Umkreis (Arezzo oder Ponticino) oder dem Flughafen Florenz.
                <br /><br />
                Lasst uns wissen wo ihr hinkommen möchtet und wir schauen gemeinsam nach einer Möglichkeit, wie Ihr schnell zu uns kommen könnt. Wir hoffen, dass wir allen Wünschen gerecht werden können.
            </>
    },
    {
        "title": "Muss ich für meine Ferienwohnung am Anreisetag einkaufen gehen?",
        "description":
            <>
                Wir planen für den Anreisetag (30. Mai) einen Sammeleinkauf für Euch. Dazu geben wir Euch vorab Zugang zum gemeinsamen Einkaufszettel, in den Ihr Eure Wünsche eintragen könnt. Die Einkäufe besorgen wir vorab und teilen die Kosten entsprechend auf. Bei Anreise sind die Kühlschränke dann schon gefüllt und Ihr könnt Euch zurücklehnen. Quasi wie Flaschenpost, nur mit besseren Produkten.
                <br /><br />
                Wer das italienische Einkaufserlebnis gerne selbst erfahren möchte, bekommt bei uns jederzeit Empfehlungen für eine Shoppingtour.
            </>
    },
    {
        "title": "Wie sind die Ferienwohnungen aufgeteilt? Mit wem werden ich eine Wohnung teilen? Oder habe ich eine Wohnung für mich alleine?",
        "description":
            <>
                Wenn wir wissen, wer von Euch sicher dabei ist, starten wir mit der Aufteilung der Zimmer und Wohnungen. Natürlich werden wir Familien und Freundeskreise nicht trennen :)
            </>
    },
    {
        "title": "Im RSVP Formular wird mir ein Preis angezeigt. Was soll ich damit jetzt anfangen?",
        "description":
            <>
                Die Summe, die das Formular ausspuckt, soll für Euch eine Planungsgröße sein. Das ist kein Rechnungsbetrag, der zum Zeitpunkt der Zusage fällig wird. Im März werden wir nochmal mit dieser Summe und einer Kontoverbindung auf Euch zukommen. Für uns ist erstmal wichtig, dass wir mit einer finalen Gästezahl planen können.
            </>
    },
    {
        "title": "Darf ich meinen Hund, Katze oder Pony mitbringen?",
        "description":
            <>
                Tiere sind grundsätzlich auf dem Hof erlaubt. Hund und Katze können mitgebracht 	werden, bei Ponys könnte es etwas eng werden.
                <br /><br />
                Hunde kosten € 20 pro Aufenthalt. Eure Tiere müssen sich nur mit Marinas Hund und den Hühnern, die auch auf dem Hof leben, verstehen.
            </>
    },
    {
        "title": "Ist es möglich, dass ich mit dem Camper komme und vor Ort im Camper schlafe?",
        "description":
            <>
                Selbstverständlich könnt Ihr auch mit dem Camper anreisen und dort übernachten. So spart Ihr Euch die Übernachtungskosten. Das Frühstücksangebot könnt Ihr trotzdem mitbenutzen.
                <br /><br />
                Auf dem Hof gibt es einen Parkplatz, auf dem Ihr durchgehend stehen könnt. Bei Bedarf können wir Euch eine 230V/16A-Leitung für die Stromversorgung zu Verfügung stellen. Toilette und Dusche könnt Ihr in einer der Ferienwohnungen mitbenutzen.
                Gebt uns bitte vorher Bescheid, falls Ihr dieses Angebot nutzen möchtet. Dann können wir alles für Euch vorbereiten.
            </>
    },
    {
        "title": "Ich bin allergisch gegen Milch vom zweiköpfigen Einhorn. Bekomme ich trotzdem was zu Essen?",
        "description":
            <>
                Vegane und vegetarische Alternativen wird es vor Ort auch geben. Sollten wir sonst etwas beachten, lasst es uns gerne wissen.
            </>
    },
    {
        "title": "Ich besitze nur ein T-Shirt und muss vor Ort waschen.",
        "description":
            <>
                Kein Problem! Es gibt eine Waschmaschine vor Ort diese kann für € 5 / Waschgang  inklusive Waschmittel genutzt werden.
            </>
    },
    {
        "title": "Was muss ich alles mitbringen? Wie sind die Ferienwohnungen ausgestattet?",
        "description":
            <>
                Jedes Ferienhaus verfügt über eine voll ausgestattete Küche mit Kühlschrank. Handtücher und Bettwäsche sind auch vorhanden. Wenn ihr in den Pool springen wollt, bringt Euch bitte große Handtücher mit. Gegen einen Aufpreis von € 5 / Stück, könnt Ihr Handtücher für den Pool vor Ort leihen.
            </>
    }
]


function Italy() {
    const [resetNavPosition, setResetNavPosition] = useState(false);

    const location = useLocation();
    const { hash, pathname, search } = location;
    useEffect(() => {
        window.scrollTo(0, 0);
        setResetNavPosition(true)
    }, []);

    const { guest, rsvp } = useContext(guestDataContext);

    if (guest && guest?.status === "success" && guest?.invitedEvents?.italy) {
        return (
            <div>
                <header>
                    <Header countdownEnd={'June 1, 2024 00:00:00'} />
                </header>
                <main>
                    <NavBarEvents guest={guest} trigger={resetNavPosition} />
                    <NavBar trigger={resetNavPosition} />
                    {pathname === "/" ?
                        <EventInfo guest={guest} />
                        : <Outlet />
                    }
                    <Footer />
                </main>
            </div>
        )
    }
    else {
        return (
            <LoadingScreen guestStatus={guest?.status} rsvp={rsvp} />
        )
    }
}

export default Italy;
